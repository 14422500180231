import axios from "axios";
import { urlRequest } from "../components/constants";

export const postFcmTokenAndroid = async (token) => {
  let params = {
    fcm: token,
  };

  let url = urlRequest + "pushToken/";
  let tokenSend = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenSend}`,
  };
  return await axios
    .post(url, params, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
    });
};
