/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón que abre el modal de documento
 *
 */

//#region IMPORTS
import React, { useState } from "react";
import axios from "axios";
import { pathAdminMenu, urlRequest } from "../components/constants";

//Imports bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

//FA
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//import components
import LabelForm from "../components/LabelForm.jsx";

//#endregion
const BotonAddImg = ({ album }) => {
  const [checkAdd, setCheckAdd] = useState(false);
  const [datosAlbum, setDatosAlbum] = useState({
    titulo: "",
    img: null,
  });
  console.log(album);
  //validación tamaño archivo y guardar el archivo en el objeto a enviar
  const addImg = (e) => {
    for (let i of e.target.files) {
      if (i.size > 5000000) {
        alert(
          "El archivo es demasiado grande. Escoja un archivo de menos de 5MB."
        );
        window.location.reload();
      } else {
      }
    }
    //TODO SIZE RESTRAINT
    setDatosAlbum({
      ...datosAlbum,
      img: e.target.files,
    });
  };

  //enviar imagenes
  const sendImg = async (e) => {
    //Request que envía los datos del album para darlos de alta

    let url = urlRequest + "storage/addImageToAlbum/" + album;
    var formData = new FormData();
    var imagefile = document.querySelector("#file");

    for (let i of imagefile.files) {
      formData.append(i.name, i);
    }
    let tokenSend = localStorage.getItem("token");
    return await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenSend}`,
        },
      })
      .then((response) => {
        window.location.reload();
      });
  };

  //#region RENDER
  return (
    <>
      {checkAdd != true ? (
        <>
          <Card>
            <Card.Body>
              <LabelForm texto="Editar Album" />
              <br />
              <Button
                onClick={(e) => setCheckAdd(true)}
                className="btn-secondary"
              >
                <FontAwesomeIcon icon={faPlus} /> Añadir imágenes
              </Button>
            </Card.Body>
          </Card>
          <br />
        </>
      ) : (
        <>
          <div className="animate__animated animate__fadeIn">
            <Card>
              <Card.Body>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <LabelForm texto="Seleccione las imágenes" />
                  <Form.Control
                    onChange={(e) => addImg(e)}
                    type="file"
                    id="file"
                    multiple
                    name="file"
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </Form.Group>
                <Button onClick={(e) => sendImg(e)}>Publicar imágenes</Button>
              </Card.Body>
            </Card>
          </div>
          <br />
        </>
      )}
    </>
  );
  //#endregion
};

export default BotonAddImg;
