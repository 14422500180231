/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState } from "react";

//Imports componentes
import ListaMenu from "../components/ListaMenu.jsx";
import Loader from "../components/Loader.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathMenu } from "../components/constants";

//Imports Bootstrap

//#endregion

const Menu = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);

  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathMenu} />

        {loadedTheme ? (
          <>
            <ListaMenu showLogo={true} />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Menu;
