/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React from "react";
//Imports componentes
import SectionHeader from "../components/SectionHeader.jsx";
import { pathInboxAdmin } from "../components/constants.js";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

//imports components
import TablaMensajesAdmin from "../components/TablaMensajesAdmin.jsx";
import TablaMensajesEnviados from "../components/TablaMensajesEnviados.jsx";

//#endregion

const InboxAdmin = () => {
  //#region RENDER

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathInboxAdmin} />
        <Container>
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>Bandeja de entrada</Accordion.Header>
              <Accordion.Body>
                <div className="containerMenu">
                  <Container>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body>
                            {" "}
                            <br />
                            <TablaMensajesAdmin />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Mensajes enviados</Accordion.Header>
              <Accordion.Body>
                <TablaMensajesEnviados />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </>
  );
};
//#endregion

export default InboxAdmin;
