/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón para abrir el modal de gestión de visitas.
 * @param {object} cita  objeto con las partes del json necesarias para pasarle al modal.
 *
 */

//#region IMPORTS
import React, { useState } from "react";
//Imports bootstrap
import Button from "react-bootstrap/Button";

//import componentes
import ModalVerVisita from "./ModalVerVisita.jsx";
//#endregion
const BotonModal = ({ cita, pasada }) => {
  //#region ESTADOS
  const [show, setShow] = useState(false);
  const [citaPasada] = useState(pasada);
  //Funciones handle abrir y cerrar modal
  const handleClose = () => setShow(false);
  //#endregion

  //#region RENDER
  return (
    <>
      <Button onClick={(e) => setShow(true)} className="btn-secondary">
        Detalles
      </Button>
      <ModalVerVisita
        past={citaPasada}
        onShow={show}
        onClose={handleClose}
        data={cita}
      />
    </>
  );
  //#endregion
};

export default BotonModal;
