/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Contenedor de la imagen con el logo de Espacio dependencia
 *
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";
import axios from "axios";

//Import router
import { Link } from "react-router-dom";
//Imports bootstrap
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { urlRequest } from "./constants";

//#endregion

const Logo = () => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (logo == null) {
      getCentroInfo();
    }
  });

  const getCentroInfo = async () => {
    let url = urlRequest + "users/info";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    await axios
      .get(url, { headers })
      .then((response) => {
        setLogo(response.data.centro);
      })
      .catch((error) => {});
  };

  //#region RENDER
  return (
    <div className="logoHolder">
      <Link to="/">
        <Image
          className="logoMain animate__animated animate__heartBeat"
          src={process.env.PUBLIC_URL + "/assets/images/logos/" + logo + ".png"}
        />
      </Link>
    </div>
  );
  //#endregion
};

export default Logo;
