import { useState } from "react";

//import helper
import { getRooms } from "../helpers/getRooms";

export const useGetRooms = () => {
  //Estado para guardar el data de la respuesta de la request
  const [rooms, setRooms] = useState("");

  const getRoomList = async () => {
    let roomsResponse = await getRooms();
    setRooms(roomsResponse.data);
  };

  return {
    rooms,
    getRoomList,
  };
};
