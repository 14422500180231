/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Texto para los enlaces al panel de admin.
 * @param {string} texto string para el nombre del botón.
 *
 */

import React from "react";

const BotonLink = ({ texto }) => {
  return (
    <nav>
      <span className="textoBotonMenu">{texto}</span>
    </nav>
  );
};

export default BotonLink;
