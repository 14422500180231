/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Modal generado para cada día y para cada cita. Desde el cual se envian las peticiones de reserva de cita.
 * @package Axios. Para realizr requests
 * @param onShow Controla el estado del modal, abierto.
 * @param onClose Controla el estado del modal, cerrado
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";
import axios from "axios";

//imports variables
import { urlRequest } from "./constants";

//Imports bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

//imports componentes
import AlertPop from "./AlertPop.jsx";

//imports custom hooks
import { useGetAuthUsers } from "../hooks/";

//#endregion

const ModalFormVisita = ({ onShow, onClose, data, fecha }) => {
  //#region ESTADOS

  //Custom hook que realiza la lógica de pedir a la api usuarios autorizados
  const { authUsers, getAUsers } = useGetAuthUsers();

  const [visitanteQ, setVisitanteQ] = useState(null);
  const [userAutorizado, setUserAutorizado] = useState(null);
  const [renderAlert, setRenderAlert] = useState(null);
  const [errorSimul, setErrorSimul] = useState(null);

  //#endregion
  //#region FUNCIONES

  //Función que controla los datos del array de acompañantes.
  let arrayVisitantes = [];
  const handleCheckVisitantes = (e) => {
    if (e.target.checked === true) {
      arrayVisitantes.push(e.target.value);
      console.log(e.target.value);
    } else {
      let indexBorrar = arrayVisitantes.indexOf(e.target.value);
      arrayVisitantes.splice(indexBorrar, 1);
    }

    return arrayVisitantes;
  };

  //Función que envía la request para reservar una visita
  const reqReservar = async (e) => {
    let visita = {
      id: data.id,
      acomp: arrayVisitantes,
    };

    let url = urlRequest + "citas/reservar";
    let params = visita;
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        if (response.data === "errV001") {
          setErrorSimul(true);
          setRenderAlert(true);
        } else if (response.data === "errV002") {
          alert(
            "El residente solicitado ya tiene una visita programada a esa hora"
          );
          window.location.replace("/bookVisit");
        } else if (response.data === "errV003") {
          alert("Cita no encontrada");
          window.location.replace("/bookVisit");
        } else if (response.data === "errV004") {
          alert("Es demasiado tarde para reservar esta visita.");
          window.location.replace("/bookVisit");
        } else {
          setErrorSimul(false);
          setRenderAlert(true);

          return response;
        }
      })
      .catch((error) => {
        alert("Error al reservar la cita");
      });
  };

  //Función que controla el estado de visibilidad de la vista mediante un ternario.
  const handleVisitante = (e) => {
    setVisitanteQ(e.target.value);
  };

  useEffect(() => {
    let acom = getAUsers();
    setUserAutorizado(acom);
  }, []);

  //#endregion

  //#region RENDER
  return (
    <>
      <Modal show={onShow} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos de los acompañantes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¡Cita disponible! ¿Desea reservarla?</p>
          {visitanteQ === "Sí" ? (
            <>
              <div className="containerChecksVisitantes">
                {userAutorizado.map((datos, index) => (
                  <Container key={index}>
                    <input
                      onChange={handleCheckVisitantes}
                      type="checkbox"
                      id={index}
                      value={[
                        datos.nombre + " " + datos.apellido,
                        " " + "(" + datos.telefono + ")",
                      ]}
                    />
                    <label className="nombreAcompañante">
                      {datos.nombre} {datos.apellido} ({datos.telefono})
                    </label>
                    <br></br>
                  </Container>
                ))}
              </div>
              <div
                className="alerta"
                style={{
                  display: renderAlert === true ? "inline-block" : "none",
                }}
              >
                {errorSimul === false ? (
                  <>
                    <AlertPop
                      titulo="¡Listo!"
                      texto="Visita reservada correctamente"
                      variante="success"
                      url="/visitHistory"
                      reload="true"
                    />
                  </>
                ) : (
                  <>
                    <AlertPop
                      titulo="Error...ups"
                      texto="No puede reservar más citas de forma simultanea"
                      variante="danger"
                      url="/visitHistory"
                      reload="true"
                    />
                  </>
                )}
              </div>
              <div
                style={{
                  display: renderAlert === true ? "none" : "inline-block",
                }}
                className="containerBotonReserva"
              >
                <Button onClick={reqReservar}>Reservar Visita</Button>
              </div>
            </>
          ) : (
            <>
              <div
                className="alerta"
                style={{
                  display: renderAlert === true ? "inline-block" : "none",
                }}
              >
                {errorSimul === false ? (
                  <>
                    <AlertPop
                      titulo="¡Listo!"
                      texto="Visita reservada correctamente"
                      variante="success"
                      url="/visitHistory"
                      reload="true"
                    />
                  </>
                ) : (
                  <>
                    <AlertPop
                      titulo="Error...ups"
                      texto="No puede reservar más citas de forma simultanea"
                      variante="danger"
                      url="/visitHistory"
                      reload="true"
                    />
                  </>
                )}
              </div>
              <div
                style={{
                  display: renderAlert === true ? "none" : "inline-block",
                }}
                className="containerBotonReserva"
              >
                <Button onClick={reqReservar}>Reservar Visita</Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
  //#endregion
};

export default ModalFormVisita;
