/***
 *
 * @author: Hive Mind Solutions S.L.
 * @description Espacio dependencia: App para los centros de atención a la dependencia del futuro.
 *
 */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import App from "./App";

//imports routes
import Admin from "./routes/admin";
import Home from "./routes/Home";
import Citas from "./routes/citas";
import VisitManagement from "./routes/VisitManagement";
import UserManagement from "./routes/UserManagement";
import BookVisit from "./routes/bookVisit";
import Login from "./routes/Login";
import AuthUsers from "./routes/AuthUsers";
import VisitHistory from "./routes/VisitHistory";
import Menu from "./routes/Menu";
import Start from "./routes/Home";
import Mensajes from "./routes/Mensajes";
import Logout from "./routes/Logout";
import Inbox from "./routes/Inbox";
import AdminMenu from "./routes/AdminMenu";
import AdminEncuestas from "./routes/AdminEncuestas";
import Encuestas from "./routes/Encuestas";
import MensajesAdmin from "./routes/MensajesAdmin";
import NewMessage from "./routes/NewMessage";
import InboxAdmin from "./routes/InboxAdmin";
import { Gallery } from "./routes/Gallery";
import { AlbumPage } from "./routes/AlbumPage";
import AdminGallery from "./routes/AdminGallery";

ReactDOM.render(
  <BrowserRouter>
    <App />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="citas" element={<Citas />} />
      <Route path="Menu" element={<Menu />} />
      <Route path="Encuestas" element={<Encuestas />} />
      <Route path="admin" element={<Admin />} />
      <Route path="VisitManagement" element={<VisitManagement />} />
      <Route path="AuthUsers" element={<AuthUsers />} />
      <Route path="UserManagement" element={<UserManagement />} />
      <Route path="bookVisit" element={<BookVisit />} />
      <Route path="Login" element={<Login />} />
      <Route path="VisitHistory" element={<VisitHistory />} />
      <Route path="Mensajes" element={<Mensajes />} />
      <Route path="Start" element={<Start />} />
      <Route path="Logout" element={<Logout />} />
      <Route path="Inbox" element={<Inbox />} />
      <Route path="InboxAdmin" element={<InboxAdmin />} />
      <Route path="Encuestas" element={<Encuestas />} />
      <Route path="AdminEncuestas" element={<AdminEncuestas />} />
      <Route path="AdminMenu" element={<AdminMenu />} />
      <Route path="AdminGallery" element={<AdminGallery />} />
      <Route path="MensajesAdmin" element={<MensajesAdmin />} />
      <Route path="NewMessage" element={<NewMessage />} />
      <Route path="Gallery" element={<Gallery />} />
      <Route path="AlbumPage/:albId" element={<AlbumPage />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
