/***
 *
 * @author Hive Mind Solutions S.L.
 * @description TAB de bootstrap que utilizamos para serparar Residentes de Familiares.
 *
 */

//#region IMPORTS
import { useState, useEffect } from "react";

//import componentes
import UserTable from "./UserTable.jsx";
import BotonModalResi from "./BotonModalResi.jsx";

//import bootstrap
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";

//custom hooks
import { useGetResidents } from "../hooks";
import { useFilterList } from "../hooks";

//#endregion

const UserTab = () => {
  //Custom hook que realiza la lógica de pedir residentes
  const { residents, getResidentList } = useGetResidents();
  //Custom hook que realiza la lógica de filtrar un array
  const { filteredList, filterList } = useFilterList();

  const [listaRes, setListaRes] = useState(null);
  const [listaOriginal, setListaOriginal] = useState(null);

  useEffect(() => {
    getResidentList();
  }, []);

  useEffect(() => {
    setListaOriginal(residents);
    setListaRes(residents);
  }, [residents]);

  useEffect(() => {
    setListaRes(filteredList);
  }, [filteredList]);

  return (
    <>
      <Tabs
        defaultActiveKey="familiares"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="familiares" title="Familiares">
          <h3>Listado de Familiares</h3>
          <UserTable />
        </Tab>
        <Tab eventKey="residentes" title="Residentes">
          <h3>Listado de Residentes</h3>
          <label className="textoBarraBusqueda">
            Búsqueda por nombre de residente:{" "}
          </label>
          <input
            size="40"
            className="barraBusqueda"
            placeholder="Introducir nombre del residente"
            onChange={(event) => filterList(event, listaOriginal)}
          ></input>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre del Residente</th>
                <th>Número de habitación</th>
                <th>Guardador de hecho</th>
                <th>Familiar de referencia</th>
                <th>Acción</th>
              </tr>
            </thead>
            {listaRes &&
              listaRes.map((data, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{data.nombre}</td>
                    <td>{data.habitacion}</td>
                    <td>{data.guardador}</td>
                    <td>{data.referencia}</td>
                    <td>
                      <BotonModalResi data={data.id} />
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        </Tab>
      </Tabs>
    </>
  );
  //#endregion
};

export default UserTab;
