/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón para abrir el modal de gestión de visitas.
 * @param {object} cita  objeto con las partes del json necesarias para pasarle al modal.
 *
 */

//#region IMPORTS
import React, { useState } from "react";
//Imports bootstrap
import Button from "react-bootstrap/Button";

//import componentes
import ModalResi from "./ModalResi.jsx";

//#endregion
const BotonModalResi = ({ data }) => {
  //#region ESTADOS

  const [show, setShow] = useState(false);
  //Funciones handle abrir y cerrar modal
  const handleClose = () => setShow(false);
  //#endregion

  //#region RENDER
  return (
    <>
      <Button onClick={() => setShow(true)} className="btn-secondary">
        Editar
      </Button>
      <ModalResi onShow={show} onClose={handleClose} data={data} />
    </>
  );
  //#endregion
};

export default BotonModalResi;
