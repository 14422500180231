/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";
import { pathAdminGallery, urlRequest } from "../components/constants";
import axios from "axios";

//Imports componentes
import Loader from "../components/Loader.jsx";
import LabelForm from "../components/LabelForm.jsx";
import SectionHeader from "../components/SectionHeader.jsx";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import AlbumListAdmin from "../components/AlbumListAdmin";

//#endregion

const AdminGallery = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);
  const [datosAlbum, setDatosAlbum] = useState({
    titulo: "",
  });

  //Función para mostrar el loader
  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);

  //Request que envía los datos del album para darlos de alta
  const sendAlbum = async (e) => {
    let url = urlRequest + "storage/createAlbum";

    let params = {
      titulo: datosAlbum.titulo,
    };
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        if (response.data === "errU001") {
          alert("Error enviando los datos");
        } else {
          alert("Album publicado correctamente"); //TODO: Alert bootstrap
          window.location.reload();
          return response;
        }
      })
      .catch((error) => {
        alert(
          "Error al crear el album, póngase en contacto con el administrador"
        ); //cambiar a alert bootstrap
      });
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        {loadedTheme ? (
          <>
            <SectionHeader infoPath={pathAdminGallery} />

            <Container>
              <Card>
                <Card.Body>
                  <Row>
                    <p>
                      En esta sección podrá crear un álbum de imágenes o bien
                      editar los que ya existen.
                    </p>
                  </Row>
                  <br></br>
                  <Row>
                    <Col xs={12}>
                      <LabelForm texto="Título del album" />
                      <input
                        className="inputMenu"
                        value={datosAlbum.titulo || ""}
                        type="text"
                        size="50"
                        onChange={(e) =>
                          setDatosAlbum({
                            ...datosAlbum,
                            titulo: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <br />
                  <Button onClick={(e) => sendAlbum()}> Crear Album </Button>
                  <Row>
                    <Col></Col>
                  </Row>
                  <AlbumListAdmin />
                </Card.Body>
              </Card>
            </Container>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default AdminGallery;
