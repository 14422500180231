/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón que abre el modal de documento
 *
 */

//#region IMPORTS
import React, { useState } from "react";
//Imports bootstrap
import Button from "react-bootstrap/Button";
//Import router
import { Link } from "react-router-dom";

//FA
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

//#endregion
const BotonAlbum = ({ albumId, text }) => {
  //#region RENDER
  return (
    <>
      <Link to={`/AlbumPage/${albumId}`}>
        <Button className="btn-secondary">
          <FontAwesomeIcon icon={faEye} /> Ver
        </Button>
      </Link>
    </>
  );
  //#endregion
};

export default BotonAlbum;
