/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón que abre el modal de documento
 *
 */

//#region IMPORTS
import React, { useState } from "react";
//Imports bootstrap
import Button from "react-bootstrap/Button";
import ModalDocumento from "./ModalDocumento.jsx";

//FA
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

//#endregion
const BotonDocumento = ({ docUrl, mesMenu, opcionMenu, documentTipo }) => {
  //#region ESTADOS
  const [show, setShow] = useState(false);

  //Funciones handle abrir y cerrar modal
  const handleClose = () => setShow(false);
  //#endregion

  //#region RENDER
  return (
    <>
      <Button onClick={(e) => setShow(true)} className="btn-secondary">
        {" "}
        <FontAwesomeIcon icon={faEye} /> Ver
      </Button>

      <ModalDocumento
        docUrl={docUrl}
        documentType={documentTipo}
        onShow={show}
        onClose={handleClose}
        mesMenu={mesMenu}
        opcionMenu={documentTipo}
      />
    </>
  );
  //#endregion
};

export default BotonDocumento;
