/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Link para abrir el modal con el contenido del mensaje
 *
 *
 */

import React, { useState } from "react";
import axios from "axios";
import { urlRequest } from "./constants";

//import Componentes
import ModalMensaje from "./ModalMensaje.jsx";

const LinkMessage = ({
  asunto,
  contenido,
  remitente,
  fecha,
  hora,
  respuestas,
  fechaLectura,
  id,
  onShow,
  onClose,
  notificacion,
  idRemitente,
}) => {
  //Estado para el asunto del mensaje
  const [show, setShow] = useState(false);

  //Funciones handle abrir y cerrar modal
  const handleClose = () => setShow(false);

  //Request para registrar mensaje como leído y hora de lectura
  const reqConfirmacionLectura = async (e) => {
    let url = urlRequest + "messages/read/" + id + "/";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        alert("Error al gestionar le confirmación de lectura del mensaje");
      });
  };

  if (show === true) {
    reqConfirmacionLectura();
  }

  return (
    <>
      <a href="#" className="linkMensaje" onClick={(e) => setShow(true)}>
        <span className="asuntoMensaje">
          {" "}
          {remitente}: {asunto}
        </span>
      </a>
      <ModalMensaje
        onShow={show}
        onClose={handleClose}
        asunto={asunto}
        contenido={contenido}
        remitente={remitente}
        fecha={fecha}
        hora={hora}
        respuestas={respuestas}
        id={id}
        notificacion={notificacion}
        readdate={fechaLectura}
        remitenteId={idRemitente}
      />
    </>
  );
};

export default LinkMessage;
