/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Label para nombres de campos en formularios
 * @param {string} texto string para el nombre del campo
 *
 */

import React from "react";

const LabelForm = ({ texto }) => {
  return (
    <>
      <label className="labelForm">{texto}</label>
    </>
  );
};

export default LabelForm;
