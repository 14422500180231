import { useState } from "react";
//Import helper
import { getFam } from "../helpers/getFam";

export const useGetFam = () => {
  //Estado para guardar el data de la respuesta de la request
  const [fam, setFam] = useState("");

  const getFamList = async () => {
    let famAux = await getFam();
    setFam(famAux.data);
  };

  return {
    fam,
    getFamList,
  };
};
