import { useState } from "react";
//Import helper
import { getResidents } from "../helpers/getResidents";

export const useGetResidents = () => {
  //Estado para guardar el data de la respuesta de la request
  const [residents, setResidents] = useState("");

  const getResidentList = async () => {
    let residentsResponse = await getResidents();
    setResidents(residentsResponse.data);
  };

  return {
    residents,
    getResidentList,
  };
};
