/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista de login en la aplicación, contiene también parte de la lógica de login (junto con app.js)
 * @package Axios. Utilizado para realizar requests
 *
 */
//#region IMPORTS
import "../App.css";
import { useState } from "react";

//import packages
import axios from "axios";

//import variables
import { urlRequest } from "../components/constants";

//imports bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

//Import Componentes
import { ReactComponent as LoginImg } from "../components/svg/loginimg.svg";

//#endregion
function Login() {
  //#region ESTADOS
  //Estado con objeto que guarda los datos que se van a enviar al back para logear.
  const [loginData, setloginData] = useState({
    username: "",
    password: "",
  });
  //const [textoToken, setTextoToken] = useState("");
  // #endregion

  //#region FUNCIONES

  const login = async (e) => {
    let url = urlRequest + "auth/token";
    let params = loginData;
    let headers = {
      "Content-Type": "application/json",
    };
    return await axios
      .post(url, params, headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        alert("Datos de inicio de sesión incorrectos");
        window.location.replace("/Login");
      });
  };

  const handleLogin = async () => {
    if (loginData.username !== "" && loginData.password !== "") {
      //peticion de login
      const loginResponse = await login(loginData);
      if ("access" in loginResponse.data) {
        localStorage.setItem("token", loginResponse.data.access);
        localStorage.setItem("refresh", loginResponse.data.refresh);
        window.location.replace("/");
      }
    }
  };

  //#endregion

  //#region RENDER
  return (
    <>
      <center>
        <Container>
          <Row>
            <Col xs={4}>
              <div className="imagenLogin">
                <LoginImg />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div className="formLogin">
                <label className="labelLogin">Email:</label>
                <br></br>
                <input
                  value={loginData.username || ""}
                  type="text"
                  onChange={(e) =>
                    setloginData({
                      ...loginData,
                      username: e.target.value,
                    })
                  }
                />
                <br></br>
                <label className="labelLogin">Contraseña:</label>
                <br></br>
                <input
                  type="password"
                  value={loginData.password || ""}
                  onChange={(e) =>
                    setloginData({
                      ...loginData,
                      password: e.target.value,
                    })
                  }
                />
              </div>
              <Col>
                <Button onClick={handleLogin} variant="primary">
                  Iniciar sesión
                </Button>
              </Col>
              <Col xs={8}>
                <div className="imgLogin">
                  <Image
                    src={
                      process.env.PUBLIC_URL + "/assets/images/logoVertical.png"
                    }
                    rounded
                  />
                </div>
                <p>Hive Mind Solutions S.L.</p>
                <a href="https://storyset.com/user">
                  User illustrations by Storyset
                </a>
              </Col>
            </Col>
          </Row>
        </Container>
      </center>
    </>
  );
}
// #endregion
export default Login;
