//import helper
import { postNewResident } from "../helpers/postNewResident";

export const usePostNewResident = () => {
  const postResident = (numHab, resName, resSur) => {
    postNewResident(numHab, resName, resSur);
  };

  return {
    postResident,
  };
};
