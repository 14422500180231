import { useState, useEffect } from "react";
//imports bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
//imports fa
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faUser } from "@fortawesome/free-solid-svg-icons";
//import hooks
import { usePostNewResident } from "../hooks";
import { useGetRooms } from "../hooks";

export const FormResident = () => {
  //Custom hook que realiza la lógica de añadir un nuevo residente
  const { postResident } = usePostNewResident();
  //Custom hook que realiza la lógica de request de habitaciones
  const { rooms, getRoomList } = useGetRooms();

  //estado booleano para controlar renderizado select de habitación residente
  const [boolHabs, setBoolHabs] = useState(false);
  //estado para guardar el número de la habitación de un residente
  const [NumHabitacion, setNumHabitacion] = useState(0);
  //Estado que recoge los datos de residentes para hacer la request.
  const [newResData, setNewResData] = useState({
    resName: "",
    resApell: "",
    habitacion: NumHabitacion,
  });

  const handleSelectHabitacion = (e) => {
    let a = parseInt(e.target.value);
    setNumHabitacion(a);
  };

  useEffect(() => {
    getRoomList();
  }, []);

  useEffect(() => {
    if (rooms) {
      setBoolHabs(true);
    }
  }, [rooms]);

  return (
    <Container>
      <Row>
        <span className="tituloSeccionUser">Alta de Residente</span>
        <p className="textoDescriptivo">
          {" "}
          Es necesario dar de alta a los nuevos residentes antes que los
          familiares, de esta forma podrá vincularlos con los familiares para
          poder trabajar con ellos.
        </p>
        <Col xs={4}>
          <div className="formNewUser">
            <label className="labelForm">
              <FontAwesomeIcon icon={faUser} /> Nombre del residente
            </label>
            <input
              value={newResData.resName || ""}
              type="text"
              onChange={(e) =>
                setNewResData({
                  ...newResData,
                  resName: e.target.value,
                })
              }
            />
            <label className="labelForm">
              <FontAwesomeIcon icon={faUser} /> Apellido del residente
            </label>
            <input
              value={newResData.resApell || ""}
              type="text"
              onChange={(e) =>
                setNewResData({
                  ...newResData,
                  resApell: e.target.value,
                })
              }
            />
            {boolHabs ? (
              <>
                <label className="labelForm">
                  <FontAwesomeIcon icon={faHashtag} /> Número de habitación
                </label>
                <select name="select" onChange={handleSelectHabitacion}>
                  <option selected="true" disabled="disabled">
                    Elija una habitación
                  </option>
                  {rooms &&
                    rooms.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.nombre}
                        </option>
                      );
                    })}
                </select>
              </>
            ) : (
              ""
            )}
            <br></br>
          </div>
        </Col>
        <Button
          onClick={() =>
            postResident(NumHabitacion, newResData.resName, newResData.resApell)
          }
          variant="primary"
        >
          Registrar Residente
        </Button>
      </Row>
    </Container>
  );
};
