/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState } from "react";
import { pathAdminEncuestas, urlRequest } from "../components/constants";
import axios from "axios";

//Imports componentes
import Loader from "../components/Loader.jsx";
import LabelForm from "../components/LabelForm.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import ListaEncuestas from "../components/ListaEncuestas";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";

//#endregion

const AdminMenu = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);
  const [datosEncuesta, setDatosEncuesta] = useState({
    titulo: "",
    link: "",
    mes: "",
  });

  //Función para mostrar el loader
  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);

  //Request que envía los datos del menú para darlos de alta
  const sendMenu = async (e) => {
    let url = urlRequest + "encuestas/add";

    let params = datosEncuesta;
    console.log(params);
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        if (response.data === "errU001") {
          alert(
            "Error. Póngase en contacto con el administrador de la aplicación"
          );
        } else {
          alert("Encuesta publicada correctamente"); //TODO: Alert bootstrap
          window.location.reload();
          return response;
        }
      })
      .catch((error) => {
        alert("Error al crear el menú. Contacte con administrador"); //cambiar a alert bootstrap
      });
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        {loadedTheme ? (
          <>
            <SectionHeader infoPath={pathAdminEncuestas} />

            <Container>
              <Card>
                <Card.Body>
                  <Row>
                    <p>
                      Rellenando los siguientes apartados, puede añadir
                      encuestas para compartirlas con los familiares.Las
                      encuestas deben ser creadas en google forms y desde ahí se
                      podrán importar utilizando la URL para compartir.
                    </p>
                  </Row>
                  <br></br>
                  <Row>
                    <Col xs={12}>
                      <LabelForm texto="Título de la encuesta:" />
                      <input
                        className="inputMenu"
                        value={datosEncuesta.titulo || ""}
                        type="text"
                        onChange={(e) =>
                          setDatosEncuesta({
                            ...datosEncuesta,
                            titulo: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col xs={12}>
                      <LabelForm texto="Enlace de google drive al menú:" />
                      <input
                        className="inputMenu"
                        value={datosEncuesta.link || ""}
                        type="text"
                        onChange={(e) =>
                          setDatosEncuesta({
                            ...datosEncuesta,
                            link: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <br />
                  <Button onClick={sendMenu}> Publicar Encuesta </Button>

                  <ListaEncuestas showLogo={false} />
                </Card.Body>
              </Card>
            </Container>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default AdminMenu;
