import axios from "axios";
import { urlRequest } from "../components/constants";

//Request que pide las habitaciones exitentes
export const getRooms = async (e) => {
  let url = urlRequest + "centro/gethabs";
  let tokenSend = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenSend}`,
  };
  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Error al recibir las habitaciones");
    });
};
