/***
 *
 * @author Hive Mind Solutions S.L.
 * @description
 * @param {string} props
 *
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";

//import bootstrap
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";

//import componentes
import Loader from "./Loader.jsx";
import EditorTexto from "./EditorTexto.jsx";
import AlertPop from "./AlertPop.jsx";

//imports packages
import "react-quill/dist/quill.snow.css"; // ES6
import DataTable from "react-data-table-component";

//Custom Hooks
import { useGetFam, useFilterMsg } from "../hooks/";

//#endregion

const UserTable = () => {
  //Custom hook que realiza la lógica de añadir un nuevo residente
  const { fam, getFamList } = useGetFam();
  //Custom hook que realiza la lógica de filtrar un array
  const { filteredListMsg, filterListMsg } = useFilterMsg();

  const [mensajeGlobal, setMensajeGlobal] = useState("false");
  const [listaFam, setListaFam] = useState(null);
  const [listaOriginal, setListaOriginal] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [arrayReceptores] = useState([]);
  const [destinatario, setDestinatario] = useState([]);
  const [titulo, setTitulo] = useState([]);

  const [receptor] = useState(false);

  const [idFam] = useState(false);
  //Estado en el que se guardan los familiares seleccionados para recibir el mensaje
  const [familiarReceptor] = useState({
    idFamiliar: idFam,
    seleccionado: receptor,
  });
  const [habilitarRespuesta, setHabilitarRespuesta] = useState(false);

  //#endregion

  const [columns] = useState([
    {
      name: "Familiar",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Residente",
      selector: (row) => row.residente,
      sortable: true,
    },
  ]);

  const handleChange = ({ selectedRows }) => {
    let arrayId = [];
    for (let i of selectedRows) {
      arrayId.push(i.noid);
    }

    setDestinatario(arrayId);
  };

  //#region FUNCIONES

  //Funciones para las opciones de envio
  //Opcionhandle para los valores de mensaje global
  const handleCheckGlobal = (e) => {
    if (e.target.checked === true) {
      setMensajeGlobal("true");
      setDestinatario(-1);
    } else {
      setMensajeGlobal("false");
      setDestinatario(null);
    }
  };

  //Opcionhandle para los valores de mensaje para guardadores de hecho
  const handleCheckHecho = (e) => {
    if (e.target.checked === true) {
      setMensajeGlobal("true");
      setDestinatario(-2);
    } else {
      setMensajeGlobal("false");
      setDestinatario(null);
    }
  };

  //Opción habilitar respuesta
  const handleCheckRespuesta = (e) => {
    if (e.target.checked === false) {
      setHabilitarRespuesta(false);
    } else {
      setHabilitarRespuesta(true);
    }
  };

  //Función para mandar mensajes globales o no
  const handleCheckReferencia = (e) => {
    if (destinatario === -1 || destinatario === -2) {
      alert(
        "El mensaje ha sido configurado como global. Si quiere cambiar esto, desactive la opción de mensaje global en opciones"
      );
    } else {
      let recep = receptor;
      let idf = idFam;
      if (e.target.checked === true) {
        recep = true;
        idf = e.target.id;
      } else {
        recep = false;
        idf = null;
      }

      if (recep === true) {
        let idInt = parseInt(idf);
        arrayReceptores.push(idInt);
      } else {
        let indexBorrar = arrayReceptores.indexOf(recep);
        arrayReceptores.splice(indexBorrar, 1);
      }
      setDestinatario(arrayReceptores);
    }
  };

  //Función que guarda el título del mensaje
  const tituloMensaje = (e) => {
    setTitulo(e.target.value);
  };

  useEffect(() => {
    getFamList();
    setListaFam(fam);
  }, []);

  useEffect(() => {
    setHasLoaded(true);
    setListaOriginal(fam);
    setListaFam(fam);
  }, [fam]);

  useEffect(() => {
    setListaFam(filteredListMsg);
  }, [filteredListMsg]);

  //#endregion

  //#region RENDER
  return (
    <>
      {habilitarRespuesta === true ? (
        <>
          <div className="animate__animated animate__bounce">
            <Container>
              <AlertPop
                titulo="¡Listo!"
                texto="El mensaje tiene las respuestas por parte de los usuarios habilitadas."
                variante="info"
                url="/visitHistory"
                reload="false"
              />
            </Container>
          </div>
        </>
      ) : (
        <>
          <div className="animate__animated animate__bounce">
            <Container>
              <AlertPop
                titulo="¡Listo!"
                texto="El mensaje tiene las respuestas por parte de los usuarios deshabilitadas."
                variante="warning"
                url="/visitHistory"
                reload="false"
              />
            </Container>
          </div>
        </>
      )}
      {mensajeGlobal === "true" ? (
        <>
          <div className="animate__animated animate__bounce">
            <Container>
              <AlertPop
                titulo="¡Listo!"
                texto="Este mensaje está configurado como global o como mensaje para guardadores de hecho. Y Llegará a todos los usuarios si es global o solo a los usuarios registrados como guardadores de hecho dependiendo de la opción elegida ¡Revisálo antes de enviar!"
                variante="success"
                url="/visitHistory"
                reload="false"
              />
            </Container>
          </div>
        </>
      ) : (
        ""
      )}
      <Container>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Opciones de envío</Accordion.Header>
            <Accordion.Body>
              {" "}
              <Container>
                <label className="textoBarraBusqueda">Mensaje global</label>
                <input
                  value="global"
                  type="checkbox"
                  id="MensajeGlobal"
                  onChange={handleCheckGlobal}
                />
                <br />
                <label className="textoBarraBusqueda">
                  Mensaje para guardadores de hecho
                </label>
                <input
                  value="grupoHecho"
                  type="checkbox"
                  id="grupoHecho"
                  onChange={handleCheckHecho}
                />
                <br />
                <label className="textoBarraBusqueda">
                  Habilitar respuestas
                </label>
                <input
                  value="respuestas"
                  type="checkbox"
                  id="habilitarRespuestas"
                  onChange={handleCheckRespuesta}
                />
              </Container>
            </Accordion.Body>
          </Accordion.Item>
          {mensajeGlobal != "true" ? (
            <Accordion.Item eventKey="1">
              <Accordion.Header>Seleccionar usuarios</Accordion.Header>
              <Accordion.Body>
                <label className="textoBarraBusqueda">
                  Búsqueda por nombre de familiar o residente:{" "}
                </label>
                <input
                  size="40"
                  className="barraBusqueda"
                  placeholder="Introducir nombre de familiar o residente"
                  onChange={(event) => filterListMsg(event, listaOriginal)}
                ></input>
                <DataTable
                  columns={columns}
                  data={filteredListMsg}
                  selectableRows
                  onSelectedRowsChange={handleChange}
                />
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            <></>
          )}

          <Accordion.Item eventKey="2">
            <Accordion.Header>Redactar Mensaje</Accordion.Header>
            <Accordion.Body>
              <label className="textoBarraBusqueda">Título del mensaje: </label>
              <input
                size="40"
                className="barraBusqueda"
                placeholder="Introducir título del mensaje"
                onChange={tituloMensaje}
              ></input>
              {habilitarRespuesta === true ? (
                <>
                  <EditorTexto
                    id={destinatario}
                    title={titulo}
                    habRespuesta={false}
                  />
                </>
              ) : (
                <>
                  <EditorTexto
                    id={destinatario}
                    title={titulo}
                    habRespuesta={true}
                  />
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
  //#endregion
};

export default UserTable;
