/***
 * @author: Hive Mind Solutions S.L.
 * @description Vista que muestra el menú de gestión de usuarios.
 */
//#region IMPORTS
import { useState } from "react";

//imports variables
import { pathUserMan } from "../components/constants";

//imports bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";

//import compontentes
import SectionHeader from "../components/SectionHeader.jsx";
import UserTab from "../components/UserTab.jsx";
import Loader from "../components/Loader.jsx";
import { FormResident } from "../components/FormResident.jsx";
import { FormFam } from "../components/FormFam.jsx";
//#endregion

const UserManagement = () => {
  //#region ESTADOS
  const [hasLoaded, setHasLoaded] = useState(false);
  //Estado que define el tipo de usuario con el que vamos a trabajar. Y hace que se active el ternario que controla la visibilidad de la vista.
  const [tipoUsuario, setTipoUsuario] = useState(null);
  //#endregion

  //#region FUNCIONES
  setTimeout(function () {
    setHasLoaded(true);
  }, 300);

  //Handle tipo usuario y select de habitación
  const handleTipoUsuario = (e) => {
    setTipoUsuario(e.target.value);
  };
  //#endregion

  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        {hasLoaded === true ? (
          <>
            <SectionHeader infoPath={pathUserMan} />

            <Container>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Nuevos usuarios</Accordion.Header>
                  <Accordion.Body>
                    <Container>
                      <Row>
                        <Col>
                          <p> Elija el tipo de usuario que va a crear:</p>
                          <Form.Control
                            as="select"
                            onChange={handleTipoUsuario}
                          >
                            <option>Familiar</option>
                            <option>Residente</option>
                          </Form.Control>
                        </Col>
                      </Row>
                    </Container>{" "}
                    {tipoUsuario === "Residente" ? (
                      <FormResident />
                    ) : (
                      <>
                        <FormFam />
                      </>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Editar usuarios</Accordion.Header>
                  <Accordion.Body>
                    <UserTab />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};
//#endregion

export default UserManagement;
