/***
 *
 * @author Hive Mind Solutions S.L.
 * @description tabla que renderiza los días tengan o no visitas disponibles.
 * @param {string} props
 *
 */

//#region IMPORTS
import { useState, useEffect } from "react";

//import bootstrap
import Table from "react-bootstrap/Table";
import BotonModalUser from "./BotonModalUser.jsx";

//import componentes
import Loader from "./Loader.jsx";

//Custom hooks
import { useGetFam, useFilterList } from "../hooks/";

//#endregion

const UserTable = () => {
  //Custom hook que realiza la lógica de pedir familiares
  const { fam, getFamList } = useGetFam();
  //Custom hook que realiza la lógica de filtrar un array
  const { filteredList, filterList } = useFilterList();

  const [listaFam, setListaFam] = useState(null);
  const [listaOriginal, setListaOriginal] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  //#endregion

  //Función para generar la lista de familiares que se va a mapear.
  const createFamList = () => {
    let arrayFamAux = [];
    for (let i of fam) {
      if (i.guardador === true) {
        i.guardador = "Sí";
      } else {
        i.guardador = "No";
      }
      if (i.referencia === true) {
        i.referencia = "Sí";
      } else {
        i.referencia = "No";
      }
      arrayFamAux.push(i);
    }
    setListaFam(arrayFamAux);
  };

  useEffect(() => {
    getFamList();
  }, []);

  useEffect(() => {
    setHasLoaded(true);
    setListaOriginal(fam);
    createFamList();
  }, [fam]);

  useEffect(() => {
    setListaFam(filteredList);
  }, [filteredList]);

  //#endregion

  //#region RENDER
  return (
    <div className="container">
      <label className="textoBarraBusqueda">
        Búsqueda por nombre de familiar o residente:{" "}
      </label>
      <input
        size="40"
        className="barraBusqueda"
        placeholder="Introducir nombre del usuario"
        onChange={(event) => filterList(event, listaOriginal)}
      ></input>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Residente</th>
            <th>Guardador</th>
            <th>F.Referencia</th>
            <th>Acción</th>
          </tr>
        </thead>
        {hasLoaded === true ? (
          <>
            {listaFam &&
              listaFam.map((data, index) => (
                <tbody key={index}>
                  <tr>
                    <td>
                      {data.nombre} {data.apellido}
                    </td>
                    <td>{data.residente}</td>
                    <td>{data.guardador}</td>
                    <td>{data.referencia}</td>
                    <td>
                      <BotonModalUser user={data} />
                    </td>
                  </tr>
                </tbody>
              ))}
          </>
        ) : (
          <>
            <div className="loaderCenter">
              <Loader />
            </div>
          </>
        )}
      </Table>
    </div>
  );
  //#endregion
};

export default UserTable;
