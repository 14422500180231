/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Componente de tabs que utilizaremos en la página de reserva de visitas para separar las citas de los meses.
 * @
 *
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";
import axios from "axios";

//imports variables
import { urlRequest } from "./constants";

//import componentes
import VisitTable from "./VisitTable.jsx";

//import bootstrap
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
//#endregion

const MonthTabCal = () => {
  const [visitasDisponibles, setVisitasDisponibles] = useState(null);

  //dummy
  const month = [];
  month[0] = "Enero";
  month[1] = "Febrero";
  month[2] = "Marzo";
  month[3] = "Abril";
  month[4] = "Mayo";
  month[5] = "Junio";
  month[6] = "Julio";
  month[7] = "Agosto";
  month[8] = "Septiembre";
  month[9] = "Octubre";
  month[10] = "Noviembre";
  month[11] = "Diciembre";

  let mesesVisitas = [];

  let fechaActual = new Date();
  let mesActual = fechaActual.getMonth();
  let mesUno = fechaActual.getMonth() + 1;
  let mesDos = fechaActual.getMonth() + 2;

  //Revisamos que ningún número sea mayor que 11 para que no de undefined en el array. Si es mayor que 11, le restamos 12.
  if (mesActual > 11) {
    mesActual = mesActual - 12;
  }

  if (mesUno > 11) {
    mesUno = mesUno - 12;
  }

  if (mesDos > 11) {
    mesDos = mesDos - 12;
  }

  const setMonthlyTables = async (e) => {
    //Funcion que pide las visitas disponibles
    let url = urlRequest + "citas/disponibles";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setVisitasDisponibles(response.data);
        return response;
      })
      .catch((error) => {
        console.log("error al recibir datos"); //alert todo
        return null;
      });
  };

  let mesPasar = []; //Array con el número de cada mes para pasar a la tabla de visitas
  mesPasar.push(mesActual, mesUno, mesDos);
  mesActual = month[mesActual];
  mesUno = month[mesUno];
  mesDos = month[mesDos];

  mesesVisitas.push(mesActual, mesUno, mesDos);

  useEffect(() => {
    setMonthlyTables();
  }, []);

  //#region RENDER
  return (
    <>
      <Tabs
        defaultActiveKey={mesesVisitas[0]}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {mesesVisitas.map((value, index) => (
          <Tab eventKey={value} title={value} key={index}>
            <h2>Visitas disponibles</h2>
            <VisitTable
              data={visitasDisponibles}
              mes={value}
              numeroMes={mesPasar[index]}
            />
          </Tab>
        ))}
      </Tabs>
    </>
  );
  //#endregion
};

export default MonthTabCal;
