import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { urlRequest } from "./constants";
import axios from "axios";

export const Album = ({ albumId }) => {
  const [hasImages, setHasImages] = useState(false);
  const [imgs, setImgs] = useState([]);
  const [galImg, setGalImg] = useState(null);

  const reqAlbumImg = async (e) => {
    let url = urlRequest + "storage/getAlbum/" + albumId + "/";
    //let url = "https://run.mocky.io/v3/d3a97012-330b-4652-8b05-10bc241c69bc";

    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setImgs(response.data);
      })
      .catch((error) => {
        alert("Error al recibir imágenes");
      });
  };

  useEffect(() => {
    reqAlbumImg();
  }, []);

  useEffect(() => {
    if (imgs.images) {
      setHasImages(true);
      let arrayIms = [];
      for (let i of imgs.images) {
        let imgObj = {
          original: i.image,
          thumbnail: i.thumbnail,
        };
        arrayIms.push(imgObj);
      }
      setGalImg(arrayIms);
    }
  }, [imgs]);

  return (
    <>
      {hasImages === true ? (
        <>
          <br />

          <p>
            Si desea ver las imágenes en formato de pantalla completa, haga
            click en el botón de la esquina inferior derecha de las imágenes. Y
            después gire su dispositivo.
          </p>

          <br />

          <ImageGallery items={galImg} />
        </>
      ) : (
        <>
          <p>Haga click en el botón para cargar las imágenes de este album..</p>
        </>
      )}
    </>
  );
};

export default Album;
