/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React from "react";

//Imports bootstrap
import Image from "react-bootstrap/Image";

//#endregion

const Logout = () => {
  localStorage.clear();
  window.location.reload();

  return (
    <div className="animate__animated animate__fadeIn">
      <Image
        src={process.env.PUBLIC_URL + "/assets/images/loader.gif"}
        rounded
      />
    </div>
  );
};

export default Logout;
