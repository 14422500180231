import { useState } from "react";
import { getAlbums } from "../helpers/getAlbums";

export const useGetAlbums = () => {
  const [albums, setAlbums] = useState("");

  const getAlbumList = async () => {
    //let arrayAl = [];
    let albumAux = await getAlbums();
    //arrayAl.push(albumAux.data);
    if (albumAux) {
      setAlbums(albumAux.data);
    }
  };

  return {
    albums,
    getAlbumList,
  };
};
