/***
 *
 * @author Hive Mind Solutions S.L.
 * @description LOader que aparece mientras la páginas están cargando
 *
 */

import React from "react";
import Image from "react-bootstrap/esm/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

const Loader = () => {
  return (
    <nav>
      <Container>
        <Row>
          <Col>
            {" "}
            <div className="loader">
              <Image
                src={process.env.PUBLIC_URL + "/assets/images/loader.gif"}
                rounded
              />
            </div>
          </Col>
        </Row>
      </Container>
    </nav>
  );
};

export default Loader;
