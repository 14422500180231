//import helper
import { postNewFam } from "../helpers/postNewFam";

export const usePostNewFam = () => {
  const postFam = (newFamData, chosenFam) => {
    postNewFam(newFamData, chosenFam);
  };

  return {
    postFam,
  };
};
