/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el acceso a las herramientas de administración.
 *
 */

//#region IMPORT
import { useState, useEffect } from "react";
//imports componentes
import BotonMenu from "../components/BotonMenu.jsx";
import Loader from "../components/Loader.jsx";
import { pathAdmin } from "../components/constants";
import SectionHeader from "../components/SectionHeader.jsx";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

// #endregion
//#region RENDER
const Admin = () => {
  //Estado que define si el contenido de la página ha cargado
  const [hasLoaded, setHasLoaded] = useState();

  const waitLoading = () => {
    setTimeout(function () {
      setHasLoaded(true);
    }, 300);
  };

  useEffect(() => {
    waitLoading();
  });

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathAdmin} />

        {hasLoaded === true ? (
          <div className="containerMenu">
            <Container>
              <nav>
                <Row>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Mensajes"
                      svg="mensajes"
                      textoBoton="Mensajes"
                      link="/MensajesAdmin"
                    />
                  </Col>

                  <Col xs={5}>
                    <BotonMenu
                      titulo="Gestión de usuarios"
                      svg="user"
                      textoBoton="Usuarios"
                      link="/userManagement"
                    />
                  </Col>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Visitas"
                      svg="visit"
                      textoBoton="Visitas"
                      link="/VisitManagement"
                    />
                  </Col>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Menús"
                      svg="menu"
                      textoBoton="Menús"
                      link="/adminMenu"
                    />
                  </Col>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Encuestas"
                      svg="encuestas"
                      textoBoton="Encuestas"
                      link="/adminEncuestas"
                    />
                  </Col>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Galería"
                      svg="gallery"
                      textoBoton="Galería de imágenes"
                      link="/AdminGallery"
                    />
                  </Col>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Cerrar sesión"
                      svg="logout"
                      textoBoton="Cerrar Sesión"
                      link="/Logout"
                    />
                  </Col>
                </Row>
              </nav>
            </Container>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
//#endregion
export default Admin;
