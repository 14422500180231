/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState } from "react";
import { pathAdminMenu, urlRequest } from "../components/constants";
import axios from "axios";

//Imports componentes
import ListaMenu from "../components/ListaMenu.jsx";
import Loader from "../components/Loader.jsx";
import LabelForm from "../components/LabelForm.jsx";
import SectionHeader from "../components/SectionHeader.jsx";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";

//#endregion

const AdminMenu = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);
  const [tipoMenu, setTipoMenu] = useState(null);
  const [mesMenu, setMesMenu] = useState(null);
  const [semanaMenu, setSemanaMenu] = useState(null);
  const [yearMenu, setYearMenu] = useState(0);
  const [datosMenu, setDatosMenu] = useState({
    mes: "",
    semana: "",
    tipo: "",
    link: "",
    year: 0,
  });

  //Función para mostrar el loader
  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);

  //Request que envía los datos del menú para darlos de alta
  const sendMenu = async (e) => {
    let url = urlRequest + "menus/add";

    let params = {
      semana: semanaMenu,
      mes: mesMenu,
      tipo: tipoMenu,
      link: datosMenu.link,
      year: yearMenu,
    };
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        if (response.data === "errU001") {
          alert("Seleccione una habitación");
        } else {
          alert("Menú publicado correctamente"); //TODO: Alert bootstrap
          window.location.reload();
          return response;
        }
      })
      .catch((error) => {
        alert("Error al crear el menú. Contacte con administrador"); //cambiar a alert bootstrap
      });
  };

  //handle del select para el tipo de menú
  const handleSelectTipoMenu = (e) => {
    setTipoMenu(e.target.value);
  };
  //handle del select para el mes del año
  const handleSelectMesMenu = (e) => {
    setMesMenu(e.target.value);
  };
  //handle del select para la semana del mes
  const handleSelectSemanaMenu = (e) => {
    setSemanaMenu(e.target.value);
  };

  //handle del select para el año
  const handleYearMenu = (e) => {
    let intYear = parseInt(e.target.value);

    setYearMenu(intYear);
  };

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        {loadedTheme ? (
          <>
            <SectionHeader infoPath={pathAdminMenu} />

            <Container>
              <Card>
                <Card.Body>
                  <Row>
                    <p>
                      Rellenando los siguientes apartados, puede añadir menús
                      para compartirlos con los familiares.La forma de subir los
                      menús, es subiéndolos a google drive y creando un link
                      público. Tras ello, poner el link en "enlace de google
                      drive al menú" Para borrarlos, solo necesita hacer click
                      en el botón "Eliminar"
                    </p>
                    <Col xs={6}>
                      <LabelForm texto="Mes" />
                      <select
                        className="inputMenu"
                        name="select"
                        onChange={handleSelectMesMenu}
                      >
                        <option selected="true" disabled="disabled">
                          Elija un mes
                        </option>
                        <option value="Enero">Enero</option>
                        <option value="Febrero">Febrero</option>
                        <option value="Marzo">Marzo</option>
                        <option value="Abril">Abril</option>
                        <option value="Mayo">Mayo</option>
                        <option value="Junio">Junio</option>
                        <option value="Julio">Julio</option>
                        <option value="Agosto">Agosto</option>
                        <option value="Septiembre">Septiembre</option>
                        <option value="Octubre">Octubre</option>
                        <option value="Noviembre">Noviembre</option>
                        <option value="Diciembre">Diciembre</option>
                      </select>
                    </Col>
                    <Col xs={6}>
                      <LabelForm texto="Semana" />
                      <select
                        className="inputMenu"
                        name="select"
                        onChange={handleSelectSemanaMenu}
                      >
                        <option selected="true" disabled="disabled">
                          Elija una semana
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </Col>
                    <Col xs={6}>
                      <LabelForm texto="Año" />
                      <select
                        className="inputMenu"
                        name="select"
                        onChange={handleYearMenu}
                      >
                        <option selected="true" disabled="disabled">
                          Año{" "}
                        </option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                      </select>
                    </Col>

                    <br></br>
                    <Col xs={6}>
                      <LabelForm texto="Tipo de menú" />
                      <select
                        className="inputMenu"
                        name="select"
                        onChange={handleSelectTipoMenu}
                      >
                        <option selected="true" disabled="disabled">
                          Elija un tipo de menú
                        </option>
                        <option value="general">Menú General</option>
                        <option value="sin sal">Menú sin sal</option>
                        <option value="sin gluten">Menú sin gluten</option>
                      </select>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col xs={12}>
                      <LabelForm texto="Enlace de google drive al menú:" />
                      <input
                        className="inputMenu"
                        value={datosMenu.link || ""}
                        type="text"
                        onChange={(e) =>
                          setDatosMenu({
                            ...datosMenu,
                            link: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <br />
                  <Button onClick={sendMenu}> Publicar Menú </Button>

                  <ListaMenu showLogo={false} />
                </Card.Body>
              </Card>
            </Container>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default AdminMenu;
