/***
 * @author: Hive Mind Solutions S.L.
 * @description vista con las herramientas para gestionar las visitas.
 * @package Axios. Utilizado para realizar requests
 */

//#region IMPORTS
import React, { useState } from "react";

//Imports bootstrap
import Container from "react-bootstrap/Container";

//imports componentes
import TablaDiasMan from "../components/TablaDiasMan.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathAdminVisitMan } from "../components/constants.js";
import Loader from "../components/Loader.jsx";

//#endregion

const VisitManagement = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);
  //Función para mostrar el loader
  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);

  //#region RENDER
  return (
    <>
      {loadedTheme ? (
        <>
          <div className="animate__animated animate__fadeIn">
            <SectionHeader infoPath={pathAdminVisitMan} />

            <div className="containerCalendar">
              <Container>
                <TablaDiasMan></TablaDiasMan>
              </Container>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
//#endregion

export default VisitManagement;
