/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Modal que muestra los detalles de un usuario
 * @parameter {string}
 * @param onShow Controla el estado del modal, abierto.
 * @param onClose Controla el estado del modal, cerrado
 * @param data Datos enviados desde donde se llama a este componente. Utilizados para mapear y renderizar.
 */
//#region IMPORTS

//imports variables
import { urlRequest } from "./constants";

import React from "react";
import axios from "axios";
//Imports bootstrap
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

//#endregion

const ModalResi = ({ onShow, onClose, data }) => {
  //Función para borrar usuarios
  const dropUser = async (e) => {
    window.confirm("¿Serguro que quiere borrar este usuario?");
    //Request borrar
    let url = urlRequest + "users/delresidente"; //TODO
    let params = {
      userid: data,
    };

    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        alert("Residente eliminado correctamente"); //TODO: Alert bootstrap
        window.location.reload();
        return response;
      })
      .catch((error) => {
        alert("Error al eliminar residente"); //cambiar a alert bootstrap
      });
  };

  //#region RENDER
  return (
    <Modal show={onShow} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <br></br>
        <div className="habitacion">
          <Container>
            <p>
              Zona de peligro<br></br> ¡Cuidado! Cuando borre un usuario se
              borrarán todos sus datos, citas y mensajes asociados.
            </p>
            <Button onClick={dropUser}>Eliminar usuario</Button>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
  //#endregion
};

export default ModalResi;
