/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón para abrir el modal de gestión de visitas.
 * @param {object} cita  objeto con las partes del json necesarias para pasarle al modal.
 *
 */

//#region IMPORTS
import React, { useState } from "react";
//Imports bootstrap
import Button from "react-bootstrap/Button";

//import componentes
import ModalUsuario from "./ModalUsuario.jsx";

//#endregion
const BotonModalUser = ({ user }) => {
  //#region ESTADOS
  const [show, setShow] = useState(false);
  //Funciones handle abrir y cerrar modal
  const handleClose = () => setShow(false);
  //#endregion

  //#region RENDER
  return (
    <>
      <Button
        onClick={(e) => setShow(true)}
        className="btn-secondary"
        id={user.id}
      >
        Editar
      </Button>
      <ModalUsuario onShow={show} onClose={handleClose} data={user} />
    </>
  );
  //#endregion
};

export default BotonModalUser;
