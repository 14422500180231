/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Modal que muestra las visitas disponibles de cada dían
 * @parameter {string}
 * @param onShow Controla el estado del modal, abierto.
 * @param onClose Controla el estado del modal, cerrado
 * @param data Datos enviados desde donde se llama a este componente. Utilizados para mapear y renderizar.
 */
//#region IMPORTS

import { useState, useEffect } from "react";
//Imports bootstrap
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//imports componentes
import BotonHora from "./BotonHora.jsx";
//#endregion

const ModalVisita = ({ onShow, onClose, data }) => {
  //#region RENDER

  const [datosCitas, setDatosCitas] = useState(null);
  const [keyZonas, setKeyZonas] = useState(null);

  useEffect(() => {
    setKeyZonas(Object.keys(data.zonas.zonaComun));
  }, []);

  return (
    <>
      <Modal show={onShow} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Visitas disponibles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br />
          {keyZonas &&
            keyZonas.map((value, index) => (
              <div className="tituloZona" key={index}>
                <p>{value}</p>
                {data?.zonas?.zonaComun[value] &&
                  data?.zonas?.zonaComun[value].map((data, ikey) => (
                    <Row key={ikey}>
                      <Col>
                        <BotonHora data={data} fecha={data.dia} />
                      </Col>
                    </Row>
                  ))}
              </div>
            ))}
        </Modal.Body>
      </Modal>
    </>
  );
};
//#endregion

export default ModalVisita;
