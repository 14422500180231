/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón de reservas que aparece en la tabla de días para pedir visitas.
 * @param {object} dia objeto con las partes del json necesarias para pasarle al modal.
 *
 * @
 *
 */

//#region IMPORTS

import React, { useState } from "react";
//Imports bootstrap
import Button from "react-bootstrap/Button";

//import componentes
import ModalVisita from "./ModalVisita.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";

//#endregion

const BotonDia = ({ dia }) => {
  //#region ESTADOS
  //Estado que utilizamos para definir si  modalVisitas se abre o se cierra
  const [show, setShow] = useState(false);
  //Funciones handle abrir y cerrar modal
  const handleClose = () => setShow(false);
  //#endregion
  //#region RENDER
  return (
    <>
      <Button onClick={(e) => setShow(true)} className="btn-secondary">
        <FontAwesomeIcon icon={faCalendarPlus} /> Reservar
      </Button>
      <ModalVisita onShow={show} onClose={handleClose} data={dia} />
    </>
  );
  //#endregion
};

export default BotonDia;
