/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón que abre el modal de documento
 *
 */

//#region IMPORTS
import React, { useState } from "react";
//Imports bootstrap
import Button from "react-bootstrap/Button";
//axios
import axios from "axios";
//FA
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

//import components
import { urlRequest } from "./constants";

//#endregion
const BotonAlbumDelete = ({ albumId }) => {
  const senDelReq = async () => {
    console.log(albumId);
    let url = urlRequest + "storage/deleteAlbum/" + albumId + "/";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    let params = { id: albumId };
    return await axios
      .post(url, params, { headers })
      .then((response) => {
        alert("Album borrado correctamente."); //TODO: Alert bootstrap
        window.location.reload();
        return response;
      })
      .catch((error) => {
        alert("Error al borrar el album."); //cambiar a alert bootstrap
      });
  };

  //#region RENDER
  return (
    <>
      <Button onClick={(e) => senDelReq()} className="btn-secondary">
        <FontAwesomeIcon icon={faTrash} /> Borrar
      </Button>
    </>
  );
  //#endregion
};

export default BotonAlbumDelete;
