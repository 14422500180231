/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import { useState, useEffect } from "react";
import axios from "axios";

//Imports componentes
import BotonMenu from "../components/BotonMenu.jsx";
import Loader from "../components/Loader.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathHome, urlRequest } from "../components/constants";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";

//import helpers
import { postFcmToken } from "../helpers/postFcmToken";
import { postFcmTokenAndroid } from "../helpers/postFcmTokenAndroid.js";

//#endregion

const Home = () => {
  const userInfo = localStorage.getItem("admin");
  //const loadedTheme = localStorage.getItem("loadedTheme");
  const [loadedTheme, setLoadedTheme] = useState();
  const [hasLoaded, setHasLoaded] = useState(null);

  const [tokenTest, setTokenTest] = useState(null);

  //Reiniciar pass
  const [newPass, setNewPass] = useState({
    newpassword: "",
    passRep: "",
  });
  const [firstLogin, setFirstLogin] = useState(null);

  //Función para comprobar si es el primer login

  const reqFirstLogin = async (e) => {
    let url = urlRequest + "users/primerlogin";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        let respuesta = JSON.stringify(response.data);
        setFirstLogin(respuesta);
        setHasLoaded(true);
        return response.data;
      })
      .catch((error) => {
        console.log("error al recibir datos"); //alert todo
      });
  };

  const checkPass = async (e) => {
    if (newPass.newpassword === newPass.passRep) {
      updatePass(newPass.password);
    } else {
      alert("Las contraseñas no coinciden.");
    }
  };

  //Request para actualizar passw de un familiar
  const updatePass = async (e) => {
    let url = urlRequest + "users/updatepass";
    let params = {
      newpassword: newPass.newpassword,
    };

    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        alert("Contraseña actualizada"); //TODO: Alert bootstrap
        window.location.reload();
        return response;
      })
      .catch((error) => {
        alert("Error al actualizar la contraseña"); //cambiar a alert bootstrap
      });
  };

  setTimeout(function () {
    setLoadedTheme(true);
  }, 300);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tgc = urlParams.get("t");
    postFcmTokenAndroid(tgc, "android");
    reqFirstLogin();
    const fcmToken = localStorage.getItem("fcm");
    setTokenTest(localStorage.getItem("fcm"));
    const os = localStorage.getItem("os");
    postFcmToken(fcmToken, os);
  }, []);

  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <div className="logoIndex">
          <SectionHeader infoPath={pathHome} />
        </div>
        {loadedTheme ? (
          <>
            <>
              {firstLogin === '{"primerlogin":false}' ? (
                <div className="containerMenu">
                  <Container>
                    <nav>
                      <Row>
                        <Col xs={5}>
                          <BotonMenu
                            titulo="Mensajes"
                            svg="mensajes"
                            textoBoton="Mensajes"
                            link="/Mensajes"
                          />
                        </Col>
                        <Col xs={5}>
                          <BotonMenu
                            titulo="Citas"
                            svg="citas"
                            textoBoton="Citas"
                            link="/citas"
                          />
                        </Col>
                        <Col xs={5}>
                          <BotonMenu
                            titulo="Menú"
                            svg="menu"
                            textoBoton="Menú"
                            link="/Menu"
                          />
                        </Col>
                        <Col xs={5}>
                          <BotonMenu
                            titulo="Encuestas"
                            svg="encuestas"
                            textoBoton="Encuestas"
                            link="/Encuestas"
                          />
                        </Col>
                        <Col xs={5}>
                          <BotonMenu
                            titulo="gallery"
                            svg="gallery"
                            textoBoton="Galería"
                            link="/Gallery"
                          />
                        </Col>
                        <Col xs={5}>
                          <BotonMenu
                            titulo="logout"
                            svg="logout"
                            textoBoton="Cerrar sesión"
                            link="/Logout"
                          />
                        </Col>
                      </Row>
                    </nav>

                    <div
                      className="botonAdmin"
                      style={{
                        display: userInfo === "true" ? "inline-block" : "none",
                      }}
                    ></div>
                  </Container>
                </div>
              ) : (
                <div className="container">
                  <Row>
                    <Col>
                      <Card.Header>
                        <h4>¡Bienvenido!</h4>
                      </Card.Header>
                      <Card>
                        <Card.Body>
                          ¡Bienvenido a Espacio Dependencia!. Por ser tu primer
                          inicio de sesión es necesario que, por seguridad,
                          cambies tu contraseña.
                          <div className="formNewUser">
                            <label className="labelLogin">
                              Nueva contraseña
                            </label>
                            <br></br>
                            <input
                              value={newPass.newpassword || ""}
                              type="password"
                              onChange={(e) =>
                                setNewPass({
                                  ...newPass,
                                  newpassword: e.target.value,
                                })
                              }
                            />
                            <br></br>
                            <label className="labelLogin">
                              Repetir contraseña
                            </label>
                            <br></br>
                            <input
                              value={newPass.passRep || ""}
                              type="password"
                              onChange={(e) =>
                                setNewPass({
                                  ...newPass,
                                  passRep: e.target.value,
                                })
                              }
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Button onClick={checkPass}>Actualizar contraseña</Button>
                  </Row>
                </div>
              )}
            </>
            )
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
//#endregion

export default Home;
