/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React from "react";
//Imports componentes
import TablaMensajes from "../components/TablaMensajes.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathInbox } from "../components/constants.js";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

//#endregion

const Inbox = () => {
  //#region RENDER

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathInbox} />

        <div className="containerMenu">
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    {" "}
                    <br />
                    <TablaMensajes />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
//#endregion

export default Inbox;
