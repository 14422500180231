import axios from "axios";
import { urlRequest } from "../components/constants";

//request recibir menús

export const getMenus = async () => {
  let url = urlRequest + "menus/get";
  let tokenSend = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenSend}`,
  };
  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Error al recibir los menús");
    });
};
