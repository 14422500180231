//URL  a la que hacemos peticiones
export const urlRequest = "https://demoapi.hmsoluciones.es/";

//Paths y nombres de los enlaces para los breadcrumbs
//Home
export const pathHome = [
  {
    link: "/",
    nombre: "Inicio",
  },
];

//Mensajes
export const pathMensajes = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/Mensajes",
    nombre: "Mensajes",
  },
];

//Inbox
export const pathInbox = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/Mensajes",
    nombre: "Mensajes",
  },
  {
    link: "/Inbox",
    nombre: "Bandeja de entrada",
  },
];

//Citas
export const pathCitas = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/citas",
    nombre: "Citas",
  },
];

//Reservar cita
export const pathReservar = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/citas",
    nombre: "Citas",
  },
  {
    link: "/bookVisit",
    nombre: "Reservar visita",
  },
];

//Mis visitas
export const pathMisVisitas = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/citas",
    nombre: "Citas",
  },
  {
    link: "/visitHistory",
    nombre: "Mis citas",
  },
];

//Acompañantes
export const pathAcomp = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/citas",
    nombre: "Citas",
  },
  {
    link: "/authUsers",
    nombre: "Acompañantes autorizados",
  },
];

//Menú
export const pathMenu = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/Menu",
    nombre: "Menús",
  },
];

//Encuestas
export const pathEncuestas = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/Encuestas",
    nombre: "Encuestas de satisfacción",
  },
];

//Galería
export const pathGallery = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/Gallery",
    nombre: "Galería de imágenes",
  },
];

//Album

//Menu admin
export const pathAdmin = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
];

//Administración mensajes
export const pathAdminMensajes = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/MensajesAdmin",
    nombre: "Administración de mensajes",
  },
];

export const pathAdminEncuestas = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/AdminEncuestas",
    nombre: "Administración de encuestas",
  },
];

export const pathAdminGallery = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/AdminGallery",
    nombre: "Administración de galerías",
  },
];

export const pathInboxAdmin = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/MensajesAdmin",
    nombre: "Administración de mensajes",
  },
];

export const pathAdminNewMess = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/MensajesAdmin",
    nombre: "Administración de mensajes",
  },
  {
    link: "/newMessage",
    nombre: "Enviar mensaje",
  },
];

export const pathAdminVisitMan = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/VisitManagement",
    nombre: "Administración de visitas",
  },
];

export const pathAdminMenu = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/adminMenu",
    nombre: "Administración de menús",
  },
];

export const pathUserMan = [
  {
    link: "/",
    nombre: "Inicio",
  },
  {
    link: "/admin",
    nombre: "Menú de administración",
  },
  {
    link: "/userManagement",
    nombre: "Administración de usuarios",
  },
];
