/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState } from "react";

//Imports componentes
import Loader from "../components/Loader.jsx";
import UserTableMsg from "../components/UserTableMsg.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathAdminNewMess } from "../components/constants";

//Imports bootstrap
import Container from "react-bootstrap/Container";

//#endregion

const NewMessage = () => {
  //#region RENDER
  //Estado para controlar si el theme ha cargado
  const [loadedTheme] = useState(true);

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathAdminNewMess} />

        <Container>
          <div className="containerMenu">
            <>
              {loadedTheme ? (
                <UserTableMsg />
              ) : (
                <Container>
                  <Loader></Loader>
                </Container>
              )}
            </>
          </div>
        </Container>
      </div>
    </>
  );
};
//#endregion

export default NewMessage;
