/***
 *
 * @author Hive Mind Solutions S.L.
 * @description TAB de bootstrap que utilizamos para serparar Residentes de Familiares.
 *
 */

//#region IMPORTS
import { useState, useEffect } from "react";
import axios from "axios";

//imports variables
import { urlRequest } from "../components/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

//import componentes
import Loader from "./Loader.jsx";
import LinkMessage from "./LinkMessage.jsx";

//import bootstrap

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//#endregion

const TablaMensajes = () => {
  //estado para controlar si el theme ha cargado
  const [loadedTheme, setLoadedTheme] = useState(false);
  //estado para filtrar mensajes
  const [listaMsg, setListaMsg] = useState(null);
  //estado que contiene mensajes sin filtrar
  const [listaOriginal, setListaOriginal] = useState(null);
  // Estado con los datos de la request
  const [mensajesRespuesta, setMensajesRespuesta] = useState(null);

  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);

  //Request mensajes
  const reqMensajes = async (e) => {
    let url = urlRequest + "messages/get/";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setMensajesRespuesta(response.data);
        return response.data;
      })
      .catch((error) => {
        alert(
          "Error al recibir los mensajes, prueba de nuevo o póngase en contacto con un administrador."
        );
      });
  };

  //Función para buscar por nombre
  const filtrarMensajes = (e) => {
    let listaFiltrada = [];
    for (let i of listaOriginal) {
      if (
        i.asunto.toLowerCase().includes(e.target.value.toLowerCase()) ||
        i.remitente.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        listaFiltrada.push(i);
      }
    }
    setListaMsg(listaFiltrada);
  };

  useEffect(() => {
    reqMensajes();
  }, []);

  useEffect(() => {
    if (mensajesRespuesta != null) {
      const arrayMensajes = [];
      for (let i of mensajesRespuesta) {
        arrayMensajes.push(i);
      }
      setListaOriginal(arrayMensajes);
      setListaMsg(arrayMensajes);
    }
  }, [mensajesRespuesta]);

  return (
    <>
      {loadedTheme ? (
        <>
          <h3>Bandeja de entrada</h3>
          <br />
          <Container>
            <Row>
              <Col>
                <label className="textoBarraBusqueda">
                  Búsqueda por asunto o emisor del mensaje:{" "}
                </label>
                <input
                  className="barraBusqueda"
                  placeholder="Introduzca texto"
                  onChange={filtrarMensajes}
                ></input>
              </Col>
            </Row>
          </Container>
          <Table striped bordered hover>
            <thead></thead>
            {listaMsg &&
              listaMsg.map((data, index) => (
                <tbody key={index}>
                  <tr>
                    {data.novedad === false ? (
                      <td>
                        <FontAwesomeIcon
                          icon={faEnvelopeOpen}
                          size="lg"
                          className="fa-mensaje"
                        />
                        <LinkMessage
                          id={data.id}
                          asunto={data.asunto}
                          remitente={data.remitente}
                          contenido={data.contenido}
                          fecha={data.fecha}
                          hora={data.hora}
                          fechaLectura={data.readdate}
                          notificacion={data.is_notif}
                          idRemitente={data.idremitente}
                        />{" "}
                        <span className="asuntoMensaje"></span>
                        <span className="fechaMensaje">
                          {" "}
                          {data.fecha} {data.hora}
                        </span>
                      </td>
                    ) : (
                      <td>
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          size="lg"
                          className="fa-mensaje"
                        />
                        <LinkMessage
                          id={data.id}
                          asunto={data.asunto}
                          remitente={data.remitente}
                          contenido={data.contenido}
                          fecha={data.fecha}
                          hora={data.hora}
                          notificacion={data.is_notif}
                          readdate={data.readdate}
                        />{" "}
                        <span className="mensajeNuevo">¡Nuevo!</span>
                        <span className="fechaMensaje">
                          {" "}
                          {data.fecha} {data.hora}
                        </span>
                      </td>
                    )}
                  </tr>
                </tbody>
              ))}
          </Table>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
  //#endregion
};

export default TablaMensajes;
