/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Botón con la hora de cada visita. Click para mandar petición que reserva la visita
 *
 */

//#region IMPORTS
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
//Imports bootstrap
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

//Import componentes
import ModalFormVisita from "./ModalFormVisita.jsx";
//#endregion

const BotonHora = ({ data, fecha }) => {
  //#region ESTADOS
  const [show, setShow] = useState(false);
  //Funciones handle abrir y cerrar modal
  const handleClose = () => setShow(false);
  //#endregion

  //#region RENDER
  return (
    <>
      <Button
        style={{ marginBottom: "2px", marginRight: "15px" }}
        className={`btn-secondary ${
          data.citasDisponibles === 0 ? "disabled btn-secondary-agotado" : ""
        }`}
        onClick={(e) => setShow(true)}
      >
        <FontAwesomeIcon icon={faClock} /> {data.horario}
      </Button>
      <Badge bg={`${data.citasDisponibles === 0 ? "danger" : "success"}`}>
        {`${
          data.citasDisponibles === 0
            ? "0 Visitas disponibles"
            : data.citasDisponibles + " Visitas disponibles"
        }`}
      </Badge>{" "}
      <ModalFormVisita
        onShow={show}
        onClose={handleClose}
        data={data}
        fecha={fecha}
      />
    </>
  );
  //#endregion
};

export default BotonHora;
