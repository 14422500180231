/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Tabla que muestra los álbumbes.
 *
 */

import React, { useState, useEffect } from "react";

//Imports componentes
import BotonAlbum from "./BotonAlbum.jsx";
//imports hooks
import { useGetAlbums } from "../hooks/useGetAlbums.js";
//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

const AlbumList = () => {
  //Custom hook que realiza la lógica de request de álbumes
  //const { menus, getMenuList } = useGetMenus();

  //Custom hook que realiza la lógica de request de menus
  const { albums, getAlbumList } = useGetAlbums();
  //estado lista de albums editada
  const [albList, setAlbList] = useState();

  //revisar el async si no funciona algo
  useEffect(() => {
    getAlbumList();
  }, []);

  useEffect(() => {
    var options = { day: "numeric", month: "numeric", year: "numeric" };
    if (albums.length != 0) {
      let arrayAlb = [];
      for (let i of albums) {
        console.log(i);
        let objAlb = {
          nombre: i.name,

          fecha: new Date(i.created_on).toLocaleString("es-ES", options),
          descrip: i.text,
          idAlb: i.id,
        };
        arrayAlb.push(objAlb);
      }
      setAlbList(arrayAlb);
    } else {
      console.log("no hay álbumes");
    }
  }, [albums]);

  return (
    <>
      <div className="containerMenu">
        <Container>
          <Row>
            <Col>
              <h1>Lista de Álbumes</h1>
              <p>
                En esta sección podrá ver los álbumes de fotos que se publican
                desde el centro.
              </p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Fecha</th>
                    <th>Ver</th>
                  </tr>
                </thead>

                {albList &&
                  albList.map((data, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>{data.nombre}</td>
                        <td>{data.fecha}</td>
                        <td>
                          <BotonAlbum
                            albumId={data.idAlb}
                            text={data.descrip}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AlbumList;
