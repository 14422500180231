//#region IMPORTS
import { useState, useEffect } from "react";

//Import packages
import { MultiSelect } from "react-multi-select-component";

//imports FA
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobile,
  faKey,
  faUser,
  faShieldAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

//imports bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

//import hooks
import { useGetResidents, usePostNewFam } from "../hooks";

export const FormFam = () => {
  //Custom hook que realiza la lógica de añadir un nuevo residente
  const { postFam } = usePostNewFam();
  //Custom hook que realiza la lógica de request de residentes
  const { residents, getResidentList } = useGetResidents();

  //estado para guardar el residente vinculado a un usuario en el select
  const [residenteElegido, setResidenteElegido] = useState([]);
  //estado para guardar el residente vinculado a un usuario en el select
  const [residentes, setResidentes] = useState({ label: "", value: "" });
  //estado para guardar el residente vinculado a un usuario en el select
  const [idRes] = useState([]);
  //Estado que nos dice si un familiar tiene la categoría "de referencia" o no.
  const [familiarReferencia, setFamiliarReferencia] = useState(false);
  //Estado que nos dice si un familiar tiene la categoría "guardador de hecho" o no.
  const [guardadorHecho, setGuardadorHecho] = useState(false);

  //Estado que recoge los datos de familiar para la request.
  const [newFamData, setNewFamData] = useState({
    email: "",
    password: "",
    rpassword: "",
    userName: "",
    userApell: "",
    telefono: "",
    referencia: familiarReferencia,
    guardador: guardadorHecho,
    resid: idRes,
  });

  //Función de que controla y guarda los datos de los checkboxes de familiar de referencia.
  const handleCheckReferencia = (e) => {
    let referencia = false;
    if (e.target.checked === true) {
      newFamData.referencia = true;
    } else {
      newFamData.referencia = false;
    }
    setFamiliarReferencia(referencia);
  };
  //Función de que controla y guarda los datos de los checkboxes de guardador de hecho.
  const handleCheckGuardador = (e) => {
    let guardador = false;
    if (e.target.checked === true) {
      newFamData.guardador = true;
    } else {
      newFamData.guardador = false;
    }
    setGuardadorHecho(guardador);
  };

  const iterateResidents = (residents) => {
    let auxiliarResidents = [];
    for (let x of residents) {
      let objResi = { label: x.nombre, value: x.id };
      auxiliarResidents.push(objResi);
    }
    setResidentes(auxiliarResidents);
  };

  useEffect(() => {
    getResidentList();
  }, []);

  useEffect(() => {
    if (residents) {
      iterateResidents(residents);
    }
  }, [residents]);

  return (
    <Container>
      <Row>
        <span className="tituloSeccionUser">Alta de Familiar</span>
        <p className="textoDescriptivo">
          {" "}
          Los familiares son los usuarios de la aplicación. Para dar de alta
          uno, por favor, rellene todos los campos salvo "familiar de
          referencia", que solo lo tendrá que marcar en caso de ser necesario
        </p>
        <Row>
          <Col>
            <label className="labelForm">
              <FontAwesomeIcon icon={faUser} /> Residente asociado
            </label>
            <br></br>
            <MultiSelect
              options={residentes}
              value={residenteElegido}
              onChange={setResidenteElegido}
              labelledBy="Select"
            />
          </Col>
        </Row>
        <Col xs={4}>
          <div className="formNewUser">
            <label className="labelForm">
              {" "}
              <FontAwesomeIcon icon={faEnvelope} /> Email:
            </label>
            <br></br>
            <input
              value={newFamData.email || ""}
              type="text"
              onChange={(e) =>
                setNewFamData({
                  ...newFamData,
                  email: e.target.value,
                })
              }
            />

            <br></br>
            <label className="labelForm">
              <FontAwesomeIcon icon={faKey} /> Contraseña:
            </label>
            <br></br>
            <input
              type="password"
              value={newFamData.password || ""}
              onChange={(e) =>
                setNewFamData({
                  ...newFamData,
                  password: e.target.value,
                })
              }
            />
            <br></br>
            <label className="labelForm">
              <FontAwesomeIcon icon={faKey} /> Repetir Contraseña:
            </label>
            <br></br>
            <input
              type="password"
              value={newFamData.rpassword || ""}
              onChange={(e) =>
                setNewFamData({
                  ...newFamData,
                  rpassword: e.target.value,
                })
              }
            />
            <br></br>
            <label className="labelForm">
              <FontAwesomeIcon icon={faShieldAlt} /> ¿Guardador de hecho?
            </label>
            <br></br>
            <input
              value={newFamData.guardadorHecho || ""}
              type="checkbox"
              onChange={handleCheckGuardador}
            />
          </div>
        </Col>
        <Col xs={4}>
          <div className="formNewUser">
            <label className="labelForm">
              <FontAwesomeIcon icon={faUser} /> Nombre del familiar:
            </label>
            <br></br>
            <input
              value={newFamData.userName || ""}
              type="text"
              onChange={(e) =>
                setNewFamData({
                  ...newFamData,
                  userName: e.target.value,
                })
              }
            />
            <br></br>
            <label className="labelForm">
              <FontAwesomeIcon icon={faUser} /> Apellido del Familiar:
            </label>
            <br></br>
            <input
              value={newFamData.userApell || ""}
              type="text"
              onChange={(e) =>
                setNewFamData({
                  ...newFamData,
                  userApell: e.target.value,
                })
              }
            />
            <br></br>
            <label className="labelForm">
              <FontAwesomeIcon icon={faMobile} /> Teléfono de contacto:
            </label>
            <br></br>
            <input
              value={newFamData.telefono || ""}
              type="text"
              onChange={(e) =>
                setNewFamData({
                  ...newFamData,
                  telefono: e.target.value,
                })
              }
            />
            <br></br>
            <label className="labelForm">
              <FontAwesomeIcon icon={faStar} /> ¿Familiar de referencia?
            </label>
            <br></br>
            <input
              value={newFamData.referencia || ""}
              type="checkbox"
              onChange={handleCheckReferencia}
            />
          </div>
        </Col>
        <Button
          onClick={() => postFam(newFamData, residenteElegido)}
          variant="primary"
        >
          Registrar Familiar
        </Button>
      </Row>
    </Container>
  );
};
