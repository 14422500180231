import { useState, useEffect } from "react";

export const useFilterList = () => {
  const [filteredList, setFilteredList] = useState([]);

  const filterList = (event, listaOriginal) => {
    let listaFiltrada = [];
    for (let i of listaOriginal) {
      if (i.residente) {
        if (
          i.nombre.toLowerCase().includes(event.target.value.toLowerCase()) ||
          i.residente.toLowerCase().includes(event.target.value.toLowerCase())
        ) {
          listaFiltrada.push(i);
        }
      } else {
        if (i.nombre.toLowerCase().includes(event.target.value.toLowerCase())) {
          listaFiltrada.push(i);
        }
      }
    }
    setFilteredList(listaFiltrada);
  };

  return {
    filterList,
    filteredList,
  };
};
