/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Tabla que muestra los menús del centro.
 *
 */

import React, { useEffect } from "react";

//Imports componentes
import BotonDocumento from "./BotonDocumento.jsx";

//imports hooks
import { useGetMenus } from "../hooks/useGetMenus.js";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import BotonBorrarMenu from "./BotonBorrarMenu";

const ListaMenu = () => {
  //Custom hook que realiza la lógica de request de menus
  const { menus, getMenuList } = useGetMenus();
  const userInfo = localStorage.getItem("admin");
  useEffect(() => {
    getMenuList();
  }, []);

  return (
    <>
      <div className="containerMenu">
        <Container>
          <Row>
            <Col>
              <h1>Lista de Menús</h1>
              <p>
                En esta sección podrá consultar los menús de comidas del centro.
                Haga click en el botón de "Ver" en la fila correspondiente al
                mes que quiera hacer la consulta de la tabla de más abajo.
              </p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Tipo de menú</th>
                    <th>Semana</th>
                    <th>Visualizar</th>

                    <th
                      style={{
                        display: userInfo === "true" ? "inline-block" : "none",
                      }}
                    >
                      Eliminar
                    </th>
                  </tr>
                </thead>

                {menus &&
                  menus.map((data, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>{data.mes}</td>
                        <td>{data.tipo}</td>
                        <td>{data.semana}</td>
                        <td>
                          <BotonDocumento
                            docUrl={data.link}
                            mesMenu={data.mes}
                            opcionMenu={data.tipo}
                            documentTipo={"menu"}
                          />
                        </td>
                        <td>
                          <BotonBorrarMenu idMenu={data.id} />
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListaMenu;
