/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Modal que muestra los detalles de un usuario
 * @parameter {string}
 * @param onShow Controla el estado del modal, abierto.
 * @param onClose Controla el estado del modal, cerrado
 * @param data Datos enviados desde donde se llama a este componente. Utilizados para mapear y renderizar.
 */
//#region IMPORTS

import { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";

//imports variables
import { urlRequest } from "./constants";

//Imports bootstrap
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

//#endregion

const ModalUsuario = ({ onShow, onClose, data }) => {
  const [newPass, setNewPass] = useState({
    newpassword: "",
    passRep: "",
  });

  //Función para borrar usuarios
  const dropUser = async (e) => {
    if (
      window.confirm(
        "¿Está seguro de que quiere borrar el usuario seleccionado?"
      ) === true
    ) {
      //Request borrar
      let url = urlRequest + "users/delete"; //TODO
      let params = {
        userid: data.id,
      };
      let tokenSend = localStorage.getItem("token");
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenSend}`,
      };

      return await axios
        .post(url, params, { headers })
        .then((response) => {
          alert("Usuario eliminado correctamente"); //TODO: Alert bootstrap
          window.location.reload();
          return response;
        })
        .catch((error) => {
          alert("Error al actualizar la contraseña"); //cambiar a alert bootstrap
        });
    } else {
      alert("El usuario no ha sido borrado");
    }
  };

  const checkPass = async (e) => {
    if (newPass.newpassword === newPass.passRep) {
      updatePass(data.id);
    } else {
      alert("Las contraseñas no coinciden.");
    }
  };

  //Request para actualizar passw de un familiar
  const updatePass = async (e) => {
    let url = urlRequest + "users/updatepass";
    let params = {
      userid: data.id,
      newpassword: newPass.newpassword,
    };

    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        if (response.data === "errU008") {
          alert(
            "Error al actualizar la contraseña. Para modificar contraseñas de otros trabajadores, contacte con el administrador principal."
          );
        } else {
          alert("Contraseña actualizada"); //TODO: Alert bootstrap
          window.location.reload();
          return response;
        }
      })
      .catch((error) => {
        alert("Error al actualizar la contraseña"); //cambiar a alert bootstrap
      });
  };

  //#region RENDER
  return (
    <Modal show={onShow} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <FontAwesomeIcon icon={faPencilRuler} className="fa-modalUser" />{" "}
          Editar usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="zonaComun">
          <Container>
            <div className="formNewUser">
              <FontAwesomeIcon icon={faUser} className="fa-modalUser" />{" "}
              <label className="labelForm">Nombre: </label> {data.nombre}{" "}
              {data.apellido}
              <br></br>
              <FontAwesomeIcon icon={faAt} className="fa-modalUser" />{" "}
              <label className="labelForm">Email: </label> {data.email}
              <br></br>
              <FontAwesomeIcon icon={faKey} className="fa-modalUser" />{" "}
              <label className="labelForm">Nueva contraseña: </label>{" "}
              <input
                value={newPass.newpassword || ""}
                type="password"
                onChange={(e) =>
                  setNewPass({
                    ...newPass,
                    newpassword: e.target.value,
                  })
                }
              />
              <br></br>
              <FontAwesomeIcon icon={faKey} className="fa-modalUser" />{" "}
              <label className="labelForm">Repetir contraseña:</label>
              <input
                value={newPass.passRep || ""}
                type="password"
                onChange={(e) =>
                  setNewPass({
                    ...newPass,
                    passRep: e.target.value,
                  })
                }
              />
            </div>
            <Button onClick={checkPass}>Actualizar contraseña</Button>
          </Container>
        </div>
        <br></br>

        <div className="habitacion">
          <Container>
            <p>
              {" "}
              Más abajo, en la "zona de peligro" podrá borrar al usuario. Hemos
              "escondido" un poco el botón, para evitar clicks no deseados y
              borrados por accidente.
            </p>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>¡Zona de Peligro!</Accordion.Header>
                <Accordion.Body>
                  <p>
                    <span className="textoPeligro">Zona de peligro</span>
                    <br></br> ¡Cuidado! Cuando borre un usuario se borrarán
                    todos sus datos, citas y mensajes asociados.
                  </p>
                  <Button onClick={dropUser}>Eliminar usuario</Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
  //#endregion
};

export default ModalUsuario;
