/***
 *
 * @author Hive Mind Solutions S.L.
 * @description tabla que renderiza los días tengan o no visitas disponibles.
 * @param {string} props
 *
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";

//import bootstrap
import Table from "react-bootstrap/Table";

//imports componentes
import BotonDia from "./BotonDia.jsx";
//#endregion

const VisitTable = (props) => {
  //#region ESTADOS
  const [diaTabla, setDiaTabla] = useState(null);

  //#endregion
  let fechaActual = new Date();
  fechaActual.setHours(0, 0, 0, 0);

  //Estado días de la semana
  const [diasSemana] = useState([
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ]);

  //#region FUNCIONES

  useEffect(() => {
    let arrayDias = [];
    if (props.data) {
      for (let x of props.data) {
        if (x.mesY === props.numeroMes + 1) {
          for (let i of x.dias) {
            arrayDias.push(i);
          }
        }
      }
      setDiaTabla(arrayDias);
    }
  }, [props]);
  //#endregion
  //#region RENDER
  return (
    <div className="container">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Día</th>
            <th>Citas</th>
            <th>Reservar</th>
          </tr>
        </thead>
        {diaTabla &&
          diaTabla.map((data, index) => {
            let xFecha = new Date();
            let diaComp = new Date(data.dia);
            let diaSemana = diasSemana[diaComp.getDay()];
            let dia = diaComp.getDate();
            let mes = diaComp.getMonth() + 1;
            let year = diaComp.getFullYear();
            if (props.numeroMes === xFecha.getMonth()) {
              if (fechaActual.getDate() <= diaComp.getDate()) {
                return (
                  <tbody key={index}>
                    <tr>
                      <td>
                        {diaSemana}, {dia}-{mes}-{year}{" "}
                      </td>
                      <td>{data.citasTotalesDisp}</td>
                      <td>
                        <BotonDia dia={data} fecha={diaComp} />
                      </td>
                    </tr>
                  </tbody>
                );
              } else {
                return "";
              }
            } else {
              return (
                <tbody key={index}>
                  <tr>
                    <td>
                      {diaSemana}, {dia}-{mes}-{year}{" "}
                    </td>
                    <td>{data.citasTotalesDisp}</td>
                    <td>
                      <BotonDia dia={data} fecha={diaComp} />
                    </td>
                  </tr>
                </tbody>
              );
            }
          })}
      </Table>
    </div>
  );
  //#endregion
};

export default VisitTable;
