import { useState } from "react";
import { getMenus } from "../helpers/getMenus";

export const useGetMenus = () => {
  const [menus, setMenus] = useState("");

  const getMenuList = async () => {
    let menuAux = await getMenus();
    setMenus(menuAux.data);
  };

  return {
    menus,
    getMenuList,
  };
};
