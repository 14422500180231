import axios from "axios";
import { urlRequest } from "../components/constants";

//request recibir menús
export const getAlbums = async () => {
  let url = urlRequest + "storage/getAlbums/";
  //let url = "https://run.mocky.io/v3/d5c59160-6891-457f-b39f-9b8244a7061f";
  let tokenSend = localStorage.getItem("token");

  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenSend}`,
  };

  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      alert("Error al recibir los álbumes");
    });
};
