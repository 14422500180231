/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Modal generado para cada día y para cada cita. Desde el cual se envian las peticiones de reserva de cita.
 * @package Axios. Para realizr requests
 * @param onShow Controla el estado del modal, abierto.
 * @param onClose Controla el estado del modal, cerrado
 */

//#region IMPORTS
import React, { useState } from "react";

//Imports bootstrap

import Modal from "react-bootstrap/Modal";

//#endregion

const ModalDocumento = ({
  onShow,
  onClose,
  docUrl,
  mesMenu,
  opcionMenu,
  documentType,
}) => {
  //#region ESTADOS

  const [fullscreen] = useState(true);
  const [nombreMenu] = useState(mesMenu);
  //  const [tipoMenu] = useState(opcionMenu);

  //#region RENDER
  return (
    <>
      <Modal fullscreen={fullscreen} show={onShow} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {nombreMenu},{opcionMenu}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {documentType === "menu" ? (
            <iframe
              title="documento"
              className="docGoogle"
              src={"https://drive.google.com/file/d/" + docUrl + "/preview"}
              allow="autoplay"
            ></iframe>
          ) : (
            <iframe
              title="documento"
              className="docGoogle"
              src={"https://docs.google.com/forms/d/e/" + docUrl + "/viewform"}
              allow="autoplay"
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
  //#endregion
};

export default ModalDocumento;
