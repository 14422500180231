/***
 *
 * @author Hive Mind Solutions S.L.
 * @description página para que los usuarios añadan acompañantes autorizados
 * @package axios. Utilizado para realizar requests
 *
 */
//#region imports
import { useEffect, useState } from "react";

//imports variables
import { pathAcomp, urlRequest } from "../components/constants";

//import packages
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";

//imports bootstrap
import { Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

//imports componentes
import SectionHeader from "../components/SectionHeader.jsx";
import AlertPop from "../components/AlertPop.jsx";
//#endregion

const AuthUsers = () => {
  //#region ESTADOS
  //Estado con los datos de acompañantes autorizados
  const [userAutorizado, setUserAutorizado] = useState(null);
  //Estado con el objeto que se enviará en la petición para dar de alta un nuevo acompañante
  const [acompData, SetAcompData] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
  });

  const [renderAlert, setRenderAlert] = useState(null);
  const [errorSimul, setErrorSimul] = useState(null);

  //#endregion
  //#region FUNCIONES
  //Función que envía los datos del acompañante que estamos dando de alta
  const reqDataAcomp = async (e) => {
    if (
      acompData.nombre &&
      acompData.apellido &&
      acompData.telefono != null &&
      " "
    ) {
      let url = urlRequest + "users/altaAcomp";
      let params = acompData;
      let tokenSend = localStorage.getItem("token");
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenSend}`,
      };
      return await axios
        .post(url, params, { headers })
        .then((response) => {
          setRenderAlert(true);
          setErrorSimul(false);
          return response;
        })
        .catch((error) => {
          setRenderAlert(true);
          setErrorSimul(true);
        });
    } else {
      setRenderAlert(true);
      setErrorSimul(true);
    }
  };

  //Funcion que pide acompañantes de un usuario
  const reqAuthUsers = async (e) => {
    let url = urlRequest + "users/queryAcomp";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setUserAutorizado(response.data);
        return response;
      })
      .catch((error) => {
        console.log("error al recibir datos"); //alert todo
      });
  };
  //#endregion
  //USE EFFECT
  useEffect(() => {
    reqAuthUsers();
  }, []);
  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathAcomp} />

        <Container>
          <Row>
            <Col>
              <Card.Header>
                <h4>Autorizar acompañante nuevo</h4>
              </Card.Header>
              <Card>
                <Card.Body>
                  Desde esta sección podrás añadir a acompañantes a tu
                  aplicación, de forma que queden registrados y puedan acceder
                  al centro contigo en tus visitas. Es tan sencillo como
                  rellenar el siguiente formulario.
                  <div className="formAuthUser">
                    <Row>
                      <Col lg="s">
                        <label className="labelAuthForm">Nombre: </label>
                        <input
                          value={acompData.nombre || ""}
                          onChange={(e) =>
                            SetAcompData({
                              ...acompData,
                              nombre: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="s">
                        <label className="labelAuthForm">Apellido: </label>
                        <input
                          value={acompData.apellido || ""}
                          onChange={(e) =>
                            SetAcompData({
                              ...acompData,
                              apellido: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="s">
                        <label className="labelAuthForm">Teléfono: </label>
                        <input
                          value={acompData.telefono || ""}
                          onChange={(e) =>
                            SetAcompData({
                              ...acompData,
                              telefono: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="alerta"
                    style={{
                      display: renderAlert === true ? "inline-block" : "none",
                    }}
                  >
                    {errorSimul === false ? (
                      <>
                        <AlertPop
                          titulo="¡Listo!"
                          texto="Acompañante dado de alta correctamente"
                          variante="success"
                          url="/AuthUsers"
                          reload="false"
                        />
                      </>
                    ) : (
                      <>
                        <AlertPop
                          titulo="Error...ups"
                          texto="Ha habido un error al dar de alta el acompañante"
                          variante="danger"
                          url="/AuthUsers"
                          reload="false"
                        />
                      </>
                    )}
                  </div>
                  <div
                    className="botonAuthUser"
                    style={{
                      display: renderAlert === true ? "none" : "inline-block",
                    }}
                  >
                    <Button onClick={reqDataAcomp}>
                      <FontAwesomeIcon icon={faUserCheck} /> Registrar
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card.Header>
                <h4>Acompañantes autorizados</h4>
              </Card.Header>
              <Card>
                <Card.Body>
                  En esta lista podrá ver los acompañantes que ya tienen
                  autorización a visitar el centro con usted.
                  <Row>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Apellido</th>
                          <th>Teléfono</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userAutorizado &&
                          userAutorizado.map((datos, index) => (
                            <tr>
                              <td>{datos.nombre}</td>
                              <td>{datos.apellido}</td>
                              <td>{datos.telefono}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
// #endregion

export default AuthUsers;
