/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Un card que actua como botón de navegación en la aplicación. En algunos casos dentro de funciones, en otros de forma genérica.
 * @param {string} titulo recibe el título que va a tener el accordion que sirve de botón.
 * @param {string} texto recibe el texto explicativo del menú.
 * @param {string} svg recibe un path para cargar un svg dentro del card.
 * @param {string} link string que define el enlace al que nos lleva el botón.Se pasa al llamar al componente BotonLink
 * @param {string} textoBoton string para el texto del botón. Se pasa al llamar al componente BotonLink
 *
 */

//#region IMPORTS
import React from "react";

//Imports bootstrap
import Card from "react-bootstrap/Card";
//Import router
import { Link } from "react-router-dom";

//Import componentes
import BotonLink from "./BotonLink.jsx";

//import svg
import { ReactComponent as Citas } from "./svg/citas.svg";
import { ReactComponent as Encuestas } from "./svg/encuestas.svg";
import { ReactComponent as Mensajes } from "./svg/mensajes.svg";
import { ReactComponent as Menu } from "./svg/menu.svg";
import { ReactComponent as Profile } from "./svg/profile.svg";
import { ReactComponent as Logout } from "./svg/logout.svg";
import { ReactComponent as BookVisit } from "./svg/bookVisit.svg";
import { ReactComponent as VisitHistory } from "./svg/visitHistory.svg";
import { ReactComponent as AuthUser } from "./svg/authUser.svg";
import { ReactComponent as User } from "./svg/userManagement.svg";
import { ReactComponent as Visit } from "./svg/visitManagement.svg";
import { ReactComponent as Inbox } from "./svg/inbox.svg";
import { ReactComponent as Notificaciones } from "./svg/notificaciones.svg";
import { ReactComponent as Salidas } from "./svg/salidas.svg";
//import { ReactComponent as Menu } from "./svg/menu.svg";
import { ReactComponent as Admin } from "./svg/admin.svg";

//#endregion

const HomeButtonSmall = ({ titulo, svg, textoBoton, link }) => {
  //#region RENDER

  let color = localStorage.getItem("colorMain");

  const getSvg = () => {
    if (svg === "mensajes") {
      return <Mensajes fill={color} />;
    } else if (svg === "encuestas") {
      return <Encuestas fill={color} />;
    } else if (svg === "citas") {
      return <Citas fill={color} />;
    } else if (svg === "logout") {
      return <Logout fill={color} />;
    } else if (svg === "menu") {
      return <Menu fill={color} />;
    } else if (svg === "profile") {
      return <Profile fill={color} />;
    } else if (svg === "bookVisit") {
      return <BookVisit fill={color} />;
    } else if (svg === "visitHistory") {
      return <VisitHistory fill={color} />;
    } else if (svg === "authUser") {
      return <AuthUser fill={color} />;
    } else if (svg === "user") {
      return <User fill={color} />;
    } else if (svg === "visit") {
      return <Visit fill={color} />;
    } else if (svg === "inbox") {
      return <Inbox fill={color} />;
    } else if (svg === "notificaciones") {
      return <Notificaciones fill={color} />;
    } else if (svg === "salidas") {
      return <Salidas fill={color} />;
    } else if (svg === "admin") {
      return <Admin fill={color} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <Card className="homeButtonsSmall">
        <Link to={link}>
          {getSvg()}

          <center>
            <BotonLink texto={textoBoton} link={link} />
          </center>
        </Link>
      </Card>
    </>
  );
  //#endregion
};

export default HomeButtonSmall;
