import { useState } from "react";
import { getEncuestas } from "../helpers/getEncuestas";

export const useGetEncuestas = () => {
  const [encuestas, setEncuestas] = useState("");

  const getEncuestaList = async () => {
    let encuestasAux = await getEncuestas();
    setEncuestas(encuestasAux.data);
  };

  return {
    encuestas,
    getEncuestaList,
  };
};
