import { useState } from "react";

import { getAuthUsers } from "../helpers/getAuthUsers";

export const useGetAuthUsers = () => {
  const [authUsers, setAuthUsers] = useState("");

  const getAUsers = async () => {
    let authUsersAux = await getAuthUsers();
    setAuthUsers(authUsersAux.data);
  };

  return {
    authUsers,
    getAUsers,
  };
};
