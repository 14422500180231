/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState } from "react";
import { pathEncuestas } from "../components/constants";

//Imports componentes
import ListaEncuestas from "../components/ListaEncuestas.jsx";
import Loader from "../components/Loader.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
//#endregion

const Encuestas = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);

  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        {loadedTheme ? (
          <>
            <SectionHeader infoPath={pathEncuestas} />

            <ListaEncuestas />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Encuestas;
