/***
 *
 * @author: Hive Mind Solutions S.L.
 * @description Muestra las visitas para administración
 *
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";
import axios from "axios";

//imports variables
import { urlRequest } from "./constants";

//Imports bootstrap
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

//import componentes
import BotonModal from "./BotonModal.jsx";
import Loader from "./Loader.jsx";

//imports packages
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

//#endregion

const TablaDiasMan = () => {
  //#region ESTADOS
  //estado para controlar la carga del contenido y mostrar o no el loader
  const [hasLoaded, setHasLoaded] = useState(null);
  //Estado con los datos de las visitas del dia actual.
  const [datosVisitasHoy, setDatosVisitasHoy] = useState(null);
  //Estado con los datos de visitas pasadas
  const [datosVisitasPasadas, setDatosVisitasPasadas] = useState(null);
  //estado boolean reander botón csv
  const [booleanRender, setBooleanRender] = useState(false);
  //estado boolean reander botón csv
  const [booleanRenderProx, setBooleanRenderProx] = useState(false);
  //estado boolean reander botón csv
  const [booleanRenderPast, setBooleanRenderPast] = useState(false);
  //estado que controla si se deben pedir o no las citas pasadas
  const [mostrarBotonPasadas, setMostrarBotonPasadas] = useState(true);

  //Estado con los datos para pasar a CSV
  const [csvData, setCsvData] = useState(null);
  //Estado citas pasadas
  const [csvDataPast, setCsvDataPast] = useState(null);
  //Estado citas próximas
  const [csvDataProx, setCsvDataProx] = useState(null);
  //Estado días de la semana
  const [diasSemana] = useState([
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ]);

  //#endregion

  //#region FUNCIONES

  //Funcion que pide las visitas del día actual
  const reqTodayVisit = async (e) => {
    let url = urlRequest + "citas/miscitas";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setDatosVisitasHoy(response.data);
        return response;
      })
      .catch((error) => {
        alert("Error al recibir visitas");
      });
  };

  //Función para cambiar el estado de pedirPasadas a true y permitir así que se realice la petición de citas pasadas.
  const cambiarEstadoPasadas = async () => {
    let respuestaRequestPasadas = await reqPasadas();

    if (respuestaRequestPasadas != null) {
      let csvPast = await extractDataPast(respuestaRequestPasadas.data);
      if (csvPast) {
        console.log("generando csv...");
        setCsvDataPast(csvPast);
        setBooleanRenderPast(true);
        setMostrarBotonPasadas(false);
      }
    }
  };

  const reqPasadas = async () => {
    let url = urlRequest + "citas/citaspasadas";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setDatosVisitasPasadas(response.data);
        return response;
      })
      .catch((error) => {
        alert("Error al recibir visitas");
      });
  };

  //Función para extraer los datos de las citas de hoy y pasarlos a CSV
  const extractData = async (info) => {
    let datosCsv = [
      [
        "Fecha",
        "Horario",
        "Nombre Residente",
        "Zona",
        "Nombre del visitante",
        "Teléfono del visitante",
        "Acompañante",
      ],
    ];
    for (let dato of info) {
      let compi = dato.acomp.replace("[", "");
      compi = compi.replace("]", "");
      compi = compi.replace("'", "");
      compi = compi.replace("'", "");
      let xFecha = new Date(Date.parse(dato.fecha));
      xFecha.setHours(0, 0, 0, 0);
      if (
        fechaActual.getDate() === xFecha.getDate() &&
        fechaActual.getMonth() === xFecha.getMonth() &&
        fechaActual.getFullYear() === xFecha.getFullYear()
      ) {
        datosCsv.push([
          dato.fecha,
          dato.horario,
          dato.nombreResidente,
          dato.zona,
          dato.nombre,
          dato.telefono,
          compi,
        ]);
      }
    }
    return datosCsv;
  };
  //Función para extraer los datos de las visitas pasadas y pasarlos a CSV
  const extractDataPast = async (info) => {
    let datosCsvPast = [
      [
        "Fecha",
        "Horario",
        "Nombre Residente",
        "Zona",
        "Nombre del visitante",
        "Teléfono del visitante",
        "Acompañante",
      ],
    ];
    for (let dato of info) {
      let compi = dato.acomp.replace("[", "");
      compi = compi.replace("]", "");
      compi = compi.replace("'", "");
      compi = compi.replace("'", "");
      let xFecha = new Date(Date.parse(dato.fecha));
      xFecha.setHours(0, 0, 0, 0);

      datosCsvPast.push([
        dato.fecha,
        dato.horario,
        dato.nombreResidente,
        dato.zona,
        dato.nombre,
        dato.telefono,
        compi,
      ]);
    }
    return datosCsvPast;
  };
  //Función para extraer los datos de las visitas próximas y pasarlos a CSV
  const extractProx = async (info) => {
    let datosCsvProx = [
      [
        "Fecha",
        "Horario",
        "Nombre Residente",
        "Zona",
        "Nombre del visitante",
        "Teléfono del visitante",
        "Acompañante",
      ],
    ];
    for (let dato of info) {
      let compi = dato.acomp.replace("[", "");
      compi = compi.replace("]", "");
      compi = compi.replace("'", "");
      compi = compi.replace("'", "");

      let xFecha = new Date(Date.parse(dato.fecha));
      xFecha.setHours(0, 0, 0, 0);
      if (
        //csv citas hoy
        fechaActual < xFecha
      ) {
        datosCsvProx.push([
          dato.fecha,
          dato.horario,
          dato.nombreResidente,
          dato.zona,
          dato.nombre,
          dato.telefono,
          compi,
        ]);
      }
    }
    return datosCsvProx;
  };

  useEffect(async () => {
    let controlRequest = await reqTodayVisit();

    if (controlRequest) {
      let csv = await extractData(controlRequest.data);
      if (csv) {
        setCsvData(csv);
        setBooleanRender(true);
      }

      let csvProx = await extractProx(controlRequest.data);
      if (csvProx) {
        setCsvDataProx(csvProx);
        setBooleanRenderProx(true);
      }

      setHasLoaded(true);
    }
  }, []);

  let fechaActual = new Date();
  fechaActual.setHours(0, 0, 0, 0);

  //#endregion

  //#region RENDER
  return (
    <>
      <h4>Visitas para hoy</h4>
      {booleanRender ? (
        <CSVLink
          className="btn btn-secondary"
          filename={"vistias_Hoy"}
          data={csvData}
        >
          <FontAwesomeIcon icon={faFileDownload} /> Descargar
        </CSVLink>
      ) : (
        ""
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Horario</th>
            <th>Residente</th>
            <th>Zona</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {hasLoaded ? (
            <>
              {datosVisitasHoy &&
                datosVisitasHoy.map((data, index) => {
                  let xFecha = new Date(Date.parse(data.fecha));

                  xFecha.setHours(0, 0, 0, 0);
                  if (
                    fechaActual.getDate() === xFecha.getDate() &&
                    fechaActual.getMonth() === xFecha.getMonth() &&
                    fechaActual.getFullYear() === xFecha.getFullYear()
                  ) {
                    return (
                      <tr key={index}>
                        <td>
                          {diasSemana[xFecha.getDay()]}, {xFecha.getDate()}-
                          {xFecha.getMonth() + 1}-{xFecha.getFullYear()}
                        </td>
                        <td>{data.horario}</td>
                        <td>{data.nombreResidente}</td>
                        <td>{data.zona}</td>
                        <td>
                          <BotonModal cita={data} />
                        </td>
                      </tr>
                    );
                  } else {
                    return "";
                  }
                })}
            </>
          ) : (
            <Loader />
          )}
        </tbody>
      </Table>
      <h4>Próximas visitas</h4>
      {booleanRenderProx ? (
        <CSVLink
          className="btn btn-secondary"
          filename={"proximas_Visitas"}
          data={csvDataProx}
        >
          <FontAwesomeIcon icon={faFileDownload} /> Descargar
        </CSVLink>
      ) : (
        ""
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Horario</th>
            <th>Usuario</th>
            <th>Residente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {hasLoaded ? (
            <>
              {datosVisitasHoy &&
                datosVisitasHoy.map((data, index) => {
                  let xFecha = new Date(Date.parse(data.fecha));
                  xFecha.setHours(0, 0, 0, 0);

                  if (fechaActual < xFecha) {
                    return (
                      <tr key={index}>
                        <td>
                          {diasSemana[xFecha.getDay()]} , {xFecha.getDate()}-
                          {xFecha.getMonth() + 1}-{xFecha.getFullYear()}
                        </td>
                        <td>{data.horario}</td>
                        <td>{data.nombre}</td>
                        <td>{data.nombreResidente}</td>

                        <td>
                          <BotonModal cita={data} />
                        </td>
                      </tr>
                    );
                  } else {
                    return "";
                  }
                })}
            </>
          ) : (
            <Loader />
          )}
        </tbody>
      </Table>
      <h4>Visitas pasadas</h4>
      {mostrarBotonPasadas ? (
        <Button onClick={cambiarEstadoPasadas}>Cargar visitas pasadas</Button>
      ) : (
        ""
      )}

      {booleanRenderPast ? (
        <>
          <CSVLink
            className="btn btn-secondary"
            filename={"visitas_Pasadas"}
            data={csvDataPast}
          >
            <FontAwesomeIcon icon={faFileDownload} /> Descargar
          </CSVLink>
        </>
      ) : (
        <></>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Usuario</th>
            <th>Residente</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {datosVisitasPasadas &&
            datosVisitasPasadas.map((data, index) => {
              let xFecha = new Date(Date.parse(data.fecha));
              xFecha.setHours(0, 0, 0, 0);

              return (
                <tr key={index}>
                  <td>
                    {diasSemana[xFecha.getDay()]},{xFecha.getDate()}-
                    {xFecha.getMonth() + 1}-{xFecha.getFullYear()}
                  </td>
                  <td>{data.nombre}</td>
                  <td>{data.nombreResidente}</td>

                  <td>
                    <BotonModal pasada="true" cita={data} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
  //#endregion
};

export default TablaDiasMan;
