//#region IMPORTS
import React from "react";
import axios from "axios";

//imports componentes
import { urlRequest } from "./constants";

//Imports bootstrap
import Button from "react-bootstrap/Button";

//imports packages
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

class EditorTexto extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: "" };
    this.handleChange = this.handleChange.bind(this);
    //const [mensaje, setMensaje] = useState();
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }],
      ["link"],
      ["image"],
    ],
  };

  formats = ["header", "bold", "italic", "underline", "list", "link", "image"];

  enviarMensaje = async (e) => {
    let objMensaje = {
      text: this.state,
      userids: this.props.id,
      title: this.props.title,
      replied_to: this.props.replied_to,
      is_notif: this.props.habRespuesta,
    };

    if (objMensaje.userids.length === 0) {
      alert("Es necesario seleccionar algún destinatario para el mensaje.");
    } else {
      if (objMensaje.title.length === 0) {
        alert("Escriba un título para su mensaje.");
      } else {
        if (this.state.text === "") {
          alert("Escriba un contenido para su mensaje.");
        } else {
          //Request para enviar el mensaje

          let url = urlRequest + "messages/send/";
          let params = objMensaje;
          let tokenSend = localStorage.getItem("token");
          let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenSend}`,
          };

          return await axios
            .post(url, params, { headers })
            .then((response) => {
              alert("Mensaje enviado correctamente");
              if (localStorage.getItem("admin") === "true") {
                window.location.replace("/InboxAdmin");
              } else {
                window.location.replace("/Inbox");
              }

              return response.data;
            })

            .catch((error) => {
              alert(
                "Error al enviar mensaje, vuelva a probar o contacte con el administrador."
              );
            });
        }
      }
    }
  };

  handleChange(value) {
    this.setState({ text: value });
  }

  render() {
    return (
      <>
        <ReactQuill
          modules={this.modules}
          formats={this.formats}
          value={this.state.text}
          onChange={this.handleChange}
        />
        <br />
        <Button onClick={this.enviarMensaje}> Enviar Mensaje </Button>
      </>
    );
  }
}

export default EditorTexto;
