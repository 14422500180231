import React from "react";
import Button from "react-bootstrap/Button";
//Import router
import { Link } from "react-router-dom";

export const HomeButtonPhone = ({ textoBoton, enlace }) => {
  return (
    <Link to={enlace}>
      <Button className="phoneButton">{textoBoton}</Button>
    </Link>
  );
};
