import React, { useState } from "react";

//import components
import AlbumList from "../components/AlbumList";
import SectionHeader from "../components/SectionHeader";
import Loader from "../components/Loader.jsx";
import { pathGallery } from "../components/constants";

export const Gallery = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);

  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        {loadedTheme ? (
          <>
            <SectionHeader infoPath={pathGallery} />
            <AlbumList />;
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Gallery;
