/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Boton para borrar encuestas
 * @param {string} texto string para el nombre del botón.
 *
 */

import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { urlRequest } from "./constants";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const BotonBorrarEncuesta = ({ idEncuesta }) => {
  const [encuestaId] = useState(idEncuesta);
  const [isAdmin, setIsAdmin] = useState(false);

  //request para eliminar menú
  const reqDelMenu = async (e) => {
    let url = urlRequest + "encuestas/delete/" + encuestaId + "/";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        alert("Encuesta eliminada correctamente");
        window.location.reload();
        return response;
      })
      .catch((error) => {
        alert("Error al eliminar la encuesta");
      });
  };
  //Si esto no va, es el async del useEfect
  useEffect(() => {
    setIsAdmin(localStorage.getItem("admin"));
  }, []);

  return (
    <nav
      style={{
        display: isAdmin === "true" ? "inline-block" : "none",
      }}
    >
      <Button onClick={reqDelMenu}>
        <FontAwesomeIcon icon={faTrash} /> Eliminar
      </Button>
    </nav>
  );
};

export default BotonBorrarEncuesta;
