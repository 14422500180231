/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Texto para los enlaces al panel de admin.
 * @param {string} texto string para el nombre del botón.
 *
 */

import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const BotonAdmin = ({ texto }) => {
  return (
    <nav>
      <Link to="/admin">
        {" "}
        <Button>{texto}</Button>
      </Link>
    </nav>
  );
};

export default BotonAdmin;
