/***
 * @author: Hive Mind Solutions S.L.
 * @description Tabla que muestra las visitas programadas para hoy, próximas y pasadas
 *
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
//#region IMPORTS

//imports variables
import { urlRequest } from "./constants";

//Imports bootstrap
import Table from "react-bootstrap/Table";

//import componentes
import BotonModal from "./BotonModal.jsx";
import Loader from "./Loader.jsx";
//#endregion

const TablaMisCitas = (props) => {
  //#region ESTADOS
  //Estado array visitas de hoy
  const [misVisitas, setMisVisitas] = useState(null);
  //Estado array visitas pasadas
  const [misVisitasPasadas, setMisVisitasPasadas] = useState(null);
  //Estado que define si el contenido de la página ha cargado
  const [hasLoaded, setHasLoaded] = useState(false);
  //Estado días de la semana
  const [diasSemana] = useState([
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ]);

  //#endregion

  //#region FUNCIONES
  const reqMisCitas = async (e) => {
    let url = urlRequest + "citas/miscitas";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setMisVisitas(response.data);
        setHasLoaded(true);
        return response;
      })
      .catch((error) => {
        alert("Error al recibir las visitas");
      });
  };

  const reqPasadas = async (e) => {
    let url = urlRequest + "citas/citaspasadas";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setMisVisitasPasadas(response.data);
        setHasLoaded(true);
        return response;
      })
      .catch((error) => {
        alert("Error al recibir las visitas");
      });
  };

  let fechaActual = new Date();
  fechaActual.setHours(0, 0, 0, 0);

  //función para guardar los datos de visitas para hoy.
  const setTodayVisit = async (e) => {};

  useEffect(() => {
    reqMisCitas();
    reqPasadas();
    setTodayVisit();
  }, []);

  //#endregion

  //#region RENDER
  return (
    <>
      {hasLoaded ? (
        <>
          <h4>Próximas visitas</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Zona</th>
                <th>Horario</th>
              </tr>
            </thead>
            <tbody>
              {misVisitas &&
                misVisitas.map((data, index) => {
                  let xFecha = new Date(Date.parse(data.fecha));
                  let diaSemana = diasSemana[xFecha.getDay()];
                  let dia = xFecha.getDate();
                  let mes = xFecha.getMonth() + 1;
                  let year = xFecha.getFullYear();
                  if (fechaActual < xFecha) {
                    return (
                      <tr key={index}>
                        <td>
                          {diaSemana}, {dia}-{mes}-{year}
                        </td>
                        <td>{data.zona}</td>
                        <td>{data.horario}</td>
                        <td>
                          <BotonModal cita={data} />
                        </td>
                      </tr>
                    );
                  } else {
                    return "";
                  }
                })}
            </tbody>
          </Table>

          <h4>Visitas pasadas</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {misVisitasPasadas &&
                misVisitasPasadas.map((data, index) => {
                  let xFecha = new Date(Date.parse(data.fecha));
                  let diaSemana = diasSemana[xFecha.getDay()];
                  let dia = xFecha.getDate();
                  let mes = xFecha.getMonth() + 1;
                  let year = xFecha.getFullYear();

                  //Meter un get year para comprar también año y ver si es de los primeros meses del siguiente año.
                  return (
                    <tr key={index}>
                      <td>
                        {" "}
                        {diaSemana}, {dia}-{mes}-{year}
                      </td>
                      <td>
                        <BotonModal pasada="true" cita={data} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <h4>Próximas visitas</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Horario</th>
              </tr>
            </thead>
            <tbody>
              <Loader />
            </tbody>
          </Table>

          <h4>Visitas pasadas</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <Loader />
            </tbody>
          </Table>

          <h4>Próximas visitas</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Horario</th>
              </tr>
            </thead>
            <tbody>
              <Loader />
            </tbody>
          </Table>

          <h4>Visitas pasadas</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <Loader />
            </tbody>
          </Table>
        </>
      )}
    </>
  );
  //#endregion
};

export default TablaMisCitas;
