import axios from "axios";
import { urlRequest } from "../components/constants";

//Función auxiliar para crear un array en el que va el id de los residentes asociados
const setParamResid = async (choosenFam) => {
  let arrayId = [];
  for (let i of choosenFam) {
    arrayId.push(i.value);
  }
  //console.log(arrayId);
  return arrayId;
};

export const postNewFam = async (newFamData, choosenFam) => {
  let ids = await setParamResid(choosenFam);

  let url = urlRequest + "users/adduser";
  //Creamos un objeto nuevo dentro de params que recibe los datos del primero creado salvo residente asociado (resid) que lo recibe aquí directamente
  let params = {
    email: newFamData.email,
    password: newFamData.password,
    rpassword: newFamData.rpassword,
    first_name: newFamData.userName,
    last_name: newFamData.userApell,
    telefono: newFamData.telefono,
    referencia: newFamData.referencia,
    resid: ids,
    guardadorHecho: newFamData.guardador,
  };

  if (
    params.email === "" ||
    params.password === "" ||
    params.rpassword === "" ||
    params.first_name === "" ||
    params.last_name === "" ||
    params.telefono === ""
  ) {
    alert(
      "Todos los campos salvo guardador de hecho y familiar de referencia son obligatorios"
    );
  } else {
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    if (params.password === params.rpassword) {
      return await axios
        .post(url, params, { headers })
        .then((response) => {
          if (response.data === "errU007") {
            alert(
              "Error al crear el usuario. Ya existe un usuario con esa dirección de correo electrónico."
            );
          } else {
            alert("Usuario creado correctamente");
            window.location.reload();
            return response;
          }
        })
        .catch((error) => {
          alert("Error al crear usuario. Contacte con administrador");
        });
    } else {
      alert("Las contraseñas no coinciden");
    }
  }
};
