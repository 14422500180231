/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState } from "react";

//Imports componentes
import BotonMenu from "../components/BotonMenu.jsx";
import Loader from "../components/Loader.jsx";
import { pathAdminMensajes } from "../components/constants";
import SectionHeader from "../components/SectionHeader.jsx";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

//#endregion

const Mensajes = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);

  //const userInfo = localStorage.getItem("admin");

  setTimeout(function () {
    setLoadedTheme(true);
  }, 300);

  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathAdminMensajes} />

        {loadedTheme === true ? (
          <div className="containerMenu">
            <Container>
              <nav>
                <Row>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Bandeja de entrada"
                      svg="inbox"
                      textoBoton="Bandeja de entrada"
                      link="/InboxAdmin"
                    />
                  </Col>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Mensaje Nuevo"
                      svg="notificaciones"
                      textoBoton="Mensaje nuevo"
                      link="/newMessage"
                    />
                  </Col>
                </Row>
              </nav>
            </Container>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
//#endregion

export default Mensajes;
