import axios from "axios";
import { urlRequest } from "../components/constants";

//Funcion que pide acompañantes de un usuario
export const getAuthUsers = async () => {
  let url = urlRequest + "users/queryAcomp";
  let tokenSend = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenSend}`,
  };
  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error al recibir datos");
    });
};
