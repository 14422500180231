/***
 * @author: Hive Mind Solutions S.L.
 * @description App main. Contiene parte de la lógica de login junto con login.js
 * @package Axios. Utilizado para realizar requests
 */

//#region IMPORTS
import "./App.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { useEffect, useState } from "react";

import { urlRequest } from "./components/constants";

//import packages
import axios from "axios";
//#endregion

function App() {
  //estado que controla si el theme específico del centro ha cargado.
  const [themeLoaded, setThemeLoaded] = useState(false);

  const logout = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  //primero, obtener el token del contexto (local storage)
  useEffect(() => {
    const token = localStorage.getItem("refresh");
    if (token) {
      //comprobar validez
      const validateToken = async () => {
        let url = urlRequest + "auth/token/refresh/";
        let params = {
          refresh: token,
        };
        let headers = {
          "Content-Type": "application/json",
        };
        await axios
          .post(url, params, headers)
          .then((response) => {
            if (response.data.access) {
              //console.log("send refresh");
              localStorage.setItem("token", response.data.access);
              if (
                window.location.pathname === "/login" &&
                localStorage.getItem("token") &&
                localStorage.getItem("refresh")
              ) {
                window.location.replace("/citas");
              }
              return true;
            } else {
              if (window.location.pathname !== "/login") {
                logout();
              }
              //console.log("then else 1");
            }
          })
          .catch((error) => {
            if (window.location.pathname !== "/login") {
              logout();
            }
          });
      };

      const getUserInfo = async () => {
        let url = urlRequest + "users/info";
        let tokenSend = localStorage.getItem("token");
        let headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenSend}`,
        };
        await axios
          .get(url, { headers })
          .then((response) => {
            changeTheme(response.data);
            if (response.status === 200) {
              if ("admin" in response.data) {
                localStorage.setItem("admin", response.data.admin);
              }
            } else {
              logout();
            }
          })
          .catch(async (error) => {
            await validateToken();
            window.location.reload();
          });
      };

      const changeTheme = (themeColors) => {
        document.documentElement.style.cssText +=
          "--fondoMain:" + themeColors.fondoMain;
        document.documentElement.style.cssText +=
          "--colorPrincipal:" + themeColors.colorPrincipal;
        document.documentElement.style.cssText +=
          "--principalOscuro:" + themeColors.principalOscuro;
        document.documentElement.style.cssText +=
          "--principalLight:" + themeColors.principalLight;
        document.documentElement.style.cssText +=
          "--principalSoft:" + themeColors.principalSoft;
        document.documentElement.style.cssText +=
          "--colorSecundario:" + themeColors.colorSecundario;
        document.documentElement.style.cssText +=
          "--secundarioLight:" + themeColors.secundarioLight;
        document.documentElement.style.cssText +=
          "--secundarioSoft:" + themeColors.secundarioSoft;
        document.documentElement.style.cssText +=
          "--botonAgotado:" + themeColors.botonAgotado;
        document.documentElement.style.cssText +=
          "--hoverTabla:" + themeColors.hoverTabla;
        if (localStorage.getItem("colorMain") == null) {
          localStorage.setItem("colorMain", themeColors.colorPrincipal);
          window.location.reload();
        }
        localStorage.setItem("colorMain", themeColors.colorPrincipal);
        setThemeLoaded(true);
        localStorage.setItem("loadedTheme", themeLoaded);
      };

      const validationRespose = validateToken(); // En caso de login correcto va a dar True.
      //En caso de login incorrecto ya se llama automatico a logout por lo que no hay que tratar excepcion
      if (validationRespose) {
        getUserInfo();
      }
    } else {
      //redirect a login
      if (window.location.pathname !== "/login") {
        window.location.replace("/login");
      }
    }
  }, []);

  return <div className="wrapper"></div>;
}

export default App;
