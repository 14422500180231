/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Modal generado para cada día y para cada cita. Desde el cual se envian las peticiones de reserva de cita.
 * @package Axios. Para realizr requests
 * @param onShow Controla el estado del modal, abierto.
 * @param onClose Controla el estado del modal, cerrado
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";
import axios from "axios";
import { urlRequest } from "./constants";

//import packages
import parse from "html-react-parser";

//Import components
import { MessageImg } from "./MessageImg";

//Imports bootstrap
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import EditorTexto from "./EditorTexto";
//#endregion

const ModalMensaje = ({
  requestRespuestas,
  onShow,
  onClose,
  asunto,
  contenido,
  remitente,
  fecha,
  id,
  hora,
  notificacion,
  readdate,
  remitenteId,
}) => {
  //#region ESTADOS

  const [asuntoMensaje] = useState(asunto);
  const [remitenteMensaje] = useState(remitente);
  const [fechaMensaje] = useState(fecha);
  const [horaMensaje] = useState(hora);
  const [show, setShow] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);

  const [readdateVar, setReaddateVar] = useState(
    new Date(readdate).toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    })
  );

  const [listaRes, setListaRes] = useState(null);

  //estado modal bootstrap
  const [fullscreen, setFullscreen] = useState(true);

  const reqRespuestas = async (e) => {
    let url = urlRequest + "messages/chain/" + id + "/";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        let arrayRespuestas = [];
        for (let i of response.data) {
          arrayRespuestas.push(i);
        }
        setListaRes(arrayRespuestas);
        const ultimoMensaje = arrayRespuestas[arrayRespuestas.length - 1];
        setLastMessage(ultimoMensaje.id);
      })
      .catch((error) => {
        alert("Error al recibir respuesta a este mensaje");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("admin") === "true") {
    } else {
      setReaddateVar(null);
    }

    if (onShow === true) {
      reqRespuestas();
    }
    setShow(onShow);
  }, [onShow]);

  //#endregion
  //#region RENDER
  return (
    <>
      <Modal fullscreen={fullscreen} show={onShow} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Asunto: {asuntoMensaje}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {listaRes &&
            listaRes.map((data, index) =>
              data.original === true ? (
                <Container>
                  <div className="mensajeInicial">
                    <span className="labelMensajeOriginal">
                      Mensaje Inicial:
                    </span>
                    <Row>
                      <Col>
                        <label className="labelForm">Emisor: </label>
                        {data.remitente}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="labelForm">Fecha:</label>
                        {fechaMensaje} {horaMensaje}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="labelForm">Mensaje:</label>
                        <div className="contenidoContainer">
                          {parse(data.contenido)}
                        </div>
                        {data.imagen != "" ? (
                          <>
                            <label className="labelForm">Imágenes:</label>
                            <p className="textImg">
                              Para ver las imágenes más grandes, ponga su
                              dispositivo móvil en horizontal.
                            </p>
                            <div className="contenidoContainer">
                              {data.imagen &&
                                data.imagen.map((content, index) =>
                                  parse(
                                    `<img className="msgImgHolder" src=data:image/jpeg;base64,${content.image}>`
                                  )
                                )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mensajeSoft">
                          {data.readdate == null ? (
                            <></>
                          ) : (
                            "Fecha y hora de lectura: " +
                            new Date(data.readdate).toLocaleString([], {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              ) : (
                <>
                  <Container key={index}>
                    <Row>
                      <hr></hr>
                      <Col>
                        <label className="labelForm">Emisor: </label>
                        {data.remitente}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="labelForm">Fecha:</label>
                        {data.fecha} {data.hora}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="labelForm">Respuesta:</label>
                        <br></br>
                        <div className="contenidoContainer">
                          {parse(data.contenido)}
                        </div>
                        {data.imagen != "" ? (
                          <>
                            <label className="labelForm">Imágenes:</label>
                            <p className="textImg">
                              Para ver las imágenes más grandes, ponga su
                              dispositivo móvil en horizontal.
                            </p>
                            <div className="contenidoContainer">
                              {data.imagen &&
                                data.imagen.map((content, index) =>
                                  parse(
                                    `<img className="msgImgHolder" src=data:image/${content.ext};base64,${content.image}>`
                                  )
                                )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="mensajeSoft">
                          {data.readdate == null ? (
                            <></>
                          ) : (
                            "Fecha y hora de lectura: " +
                            new Date(data.readdate).toLocaleString([], {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </>
              )
            )}

          {notificacion === false ? (
            <>
              <Container>
                <label className="labelForm">Escriba aquí su respuesta:</label>

                <EditorTexto
                  remitente={remitenteMensaje}
                  replied_to={lastMessage}
                  id={[1]}
                  title={asunto}
                />
              </Container>
            </>
          ) : (
            <Container>
              <p className="mensajeInformación">
                <hr />
                Este mensaje tiene las respuestas deshabilitadas
              </p>
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
  //#endregion
};

export default ModalMensaje;
