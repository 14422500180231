/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que utilizan los familiares para reservar visitas.
 *
 */
//#region IMPORTS
import React from "react";
import { pathReservar } from "../components/constants";

//import componentes
import MonthTabCal from "../components/MonthTabCal.jsx";
import SectionHeader from "../components/SectionHeader.jsx";

//#endregion
const bookVisit = () => {
  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathReservar} />
        <div className="container">
          <MonthTabCal />
        </div>
      </div>
    </>
  );
};
//#endregion

export default bookVisit;
