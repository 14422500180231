import axios from "axios";
import { urlRequest } from "../components/constants";

export const postNewResident = async (numHab, resName, resSur) => {
  let url = urlRequest + "users/addresidente";
  let params = {
    habid: numHab,
    nombre: resName + " " + resSur,
  };
  let tokenSend = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenSend}`,
  };

  return await axios
    .post(url, params, { headers })
    .then((response) => {
      if (response.data === "errU001") {
        alert("Seleccione una habitación");
      } else {
        alert("Residente dado de alta correctamente"); //TODO: Alert bootstrap
        window.location.reload();
        return response;
      }
    })
    .catch((error) => {
      alert("Error al crear usuario. Contacte con administrador"); //cambiar a alert bootstrap
    });
};
