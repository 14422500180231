/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React, { useState } from "react";

//Imports componentes
import BotonMenu from "../components/BotonMenu.jsx";
import Loader from "../components/Loader.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathMensajes } from "../components/constants";
import BotonAdmin from "../components/BotonAdmin.jsx";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

//#endregion

const Mensajes = () => {
  const [loadedTheme, setLoadedTheme] = useState(false);

  const userInfo = localStorage.getItem("admin");

  setTimeout(function () {
    setLoadedTheme(true);
  }, 300);

  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathMensajes} />
        {loadedTheme === true ? (
          <div className="containerMenu">
            <Container>
              <nav>
                <Row>
                  <Col xs={5}>
                    <BotonMenu
                      titulo="Buzón"
                      svg="inbox"
                      textoBoton="Buzón"
                      link="/Inbox"
                    />
                  </Col>
                </Row>
              </nav>
              <div
                className="botonAdmin"
                style={{
                  display: userInfo === "true" ? "inline-block" : "none",
                }}
              >
                <Row>
                  <Col>
                    <BotonAdmin texto="Acceder al menú de administración" />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
//#endregion

export default Mensajes;
