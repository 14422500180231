import { useState } from "react";

export const useFilterMsg = () => {
  const [filteredListMsg, setfilteredListMsg] = useState([]);

  const filterListMsg = (event, listaOriginal) => {
    let listaFiltrada = [];
    for (let i of listaOriginal) {
      if ("id" in i) {
        Object.defineProperty(
          i,
          "noid",
          Object.getOwnPropertyDescriptor(i, "id")
        );
      }

      delete i["id"];
      if (i.residente) {
        if (
          i.nombre.toLowerCase().includes(event.target.value.toLowerCase()) ||
          i.residente.toLowerCase().includes(event.target.value.toLowerCase())
        ) {
          listaFiltrada.push(i);
        }
      } else {
        if (i.nombre.toLowerCase().includes(event.target.value.toLowerCase())) {
          listaFiltrada.push(i);
        }
      }
    }
    setfilteredListMsg(listaFiltrada);
  };

  return {
    filterListMsg,
    filteredListMsg,
  };
};
