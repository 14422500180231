/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Breadcrumbs que aparecen en cad vista para poder navegar desde ellas.
 * @param {string} Link Enlace al que nos lleva la primera palabra.
 * @param {string} LinkDos Enlace al que nos lleva la segunda palabra.
 * @param {string} nivelUno Primer nivel de navegación
 * @param {string} nivelDos Segundo nivel de navegación
 * @package React-Router. Para realizar la navegación.
 *
 * @TODO Esto es una puta chapuza. Hay que hacer bien este componente cuando tengamos tiempo.
 *
 *
 */
//#region IMPORTS
import { useEffect, useState } from "react";

//Import router
import { Link } from "react-router-dom";

//imports bootstrap
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
//Import Componentes
import Logo from "./Logo.jsx";
import HomeButtonSmall from "./HomeButtonSmall.jsx";
import { HomeButtonPhone } from "./HomeButtonPhone.jsx";

//#endregion
const SectionHeader = ({ infoPath }) => {
  //#region Render

  const [ScrWidth, setScrWidth] = useState(window.innerWidth);
  const [movilConf, setMovilConf] = useState(false);
  const userInfo = localStorage.getItem("admin");

  useEffect(() => {
    if (ScrWidth < 500) {
      setMovilConf(true);
    }
  }, [ScrWidth]);

  return (
    <>
      <Container>
        <Card className="headerCard">
          <Row>
            <Col xs={6}>
              <Logo />
            </Col>
            {movilConf === false ? (
              <>
                <Col
                  xs={7}
                  style={{
                    display: userInfo === "true" ? "inline-block" : "none",
                  }}
                >
                  <HomeButtonSmall
                    titulo="Admin"
                    svg="admin"
                    textoBoton="Administración"
                    link="/Admin"
                  />
                </Col>
              </>
            ) : (
              <>
                <div
                  className="botonAdmin"
                  style={{
                    display: userInfo === "true" ? "inline-block" : "none",
                  }}
                >
                  <Col>
                    <HomeButtonPhone
                      textoBoton="Administración"
                      enlace="/Admin"
                    />
                  </Col>
                </div>
              </>
            )}
          </Row>
        </Card>

        <div className="breadCrumbAl">
          {infoPath &&
            infoPath.map((data, index) => (
              <Link key={index} to={data.link}>
                {" "}
                {data.nombre}
                {" / "}
              </Link>
            ))}
        </div>
      </Container>
    </>
  );
  //#endregion
};

export default SectionHeader;
