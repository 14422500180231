/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que contiene el menú principal de la aplicación.
 *
 */

//#region IMPORTS
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

//Imports componentes
import BotonMenu from "../components/BotonMenu.jsx";
import Loader from "../components/Loader.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathCitas, urlRequest } from "../components/constants";
import BotonAdmin from "../components/BotonAdmin.jsx";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { Card } from "react-bootstrap";

//#endregion

const Citas = () => {
  const [newPass, setNewPass] = useState({
    newpassword: "",
    passRep: "",
  });

  const [hasLoaded, setHasLoaded] = useState(null);
  const [firstLogin, setFirstLogin] = useState(null);

  const userInfo = localStorage.getItem("admin");

  //Función para comprobar si es el primer login

  const reqFirstLogin = async (e) => {
    let url = urlRequest + "users/primerlogin";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        let respuesta = JSON.stringify(response.data);
        setFirstLogin(respuesta);
        setHasLoaded(true);
        return response.data;
      })
      .catch((error) => {
        console.log("error al recibir datos"); //alert todo
      });
  };

  const checkPass = async (e) => {
    if (newPass.newpassword === newPass.passRep) {
      updatePass(newPass.password);
    } else {
      alert("Las contraseñas no coinciden.");
    }
  };

  //Request para actualizar passw de un familiar
  const updatePass = async (e) => {
    let url = urlRequest + "users/updatepass";
    let params = {
      newpassword: newPass.newpassword,
    };

    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };

    return await axios
      .post(url, params, { headers })
      .then((response) => {
        alert("Contraseña actualizada"); //TODO: Alert bootstrap
        window.location.reload();
        return response;
      })
      .catch((error) => {
        alert("Error al actualizar la contraseña"); //cambiar a alert bootstrap
      });
  };

  //USE EFFECT
  useEffect(() => {
    reqFirstLogin();
  }, []);

  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        {hasLoaded ? (
          <>
            <SectionHeader infoPath={pathCitas} />
            <div className="containerMenu">
              {firstLogin === '{"primerlogin":false}' ? (
                <Container>
                  <nav>
                    <Row>
                      <Col xs={5}>
                        <BotonMenu
                          titulo="Reservar Visita"
                          svg="bookVisit"
                          textoBoton="Reservar"
                          link="/bookVisit"
                        />
                      </Col>
                      <Col xs={5}>
                        <BotonMenu
                          titulo="Mis Visitas"
                          svg="visitHistory"
                          textoBoton="Mis reservas"
                          link="/visitHistory"
                        />
                      </Col>{" "}
                    </Row>
                  </nav>
                </Container>
              ) : (
                <div className="container">
                  <Row>
                    <Col>
                      <Card.Header>
                        <h4>¡Bienvenido!</h4>
                      </Card.Header>
                      <Card>
                        <Card.Body>
                          ¡Bienvenido al apartado de citas!. Por ser tu primer
                          inicio de sesión es necesario que, por seguridad,
                          cambies tu contraseña. Una vez lo hagas, podrás pasar
                          a reservar o consultar tus citas.
                          <div className="formNewUser">
                            <label className="labelLogin">
                              Nueva contraseña
                            </label>
                            <br></br>
                            <input
                              value={newPass.newpassword || ""}
                              type="password"
                              onChange={(e) =>
                                setNewPass({
                                  ...newPass,
                                  newpassword: e.target.value,
                                })
                              }
                            />
                            <br></br>
                            <label className="labelLogin">
                              Repetir contraseña
                            </label>
                            <br></br>
                            <input
                              value={newPass.passRep || ""}
                              type="password"
                              onChange={(e) =>
                                setNewPass({
                                  ...newPass,
                                  passRep: e.target.value,
                                })
                              }
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Button onClick={checkPass}>Actualizar contraseña</Button>
                  </Row>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};
//#endregion

export default Citas;
