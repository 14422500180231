/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Alerta de bootstrap que se llama en determinadas situaciones
 * @param {string} texto string para el nombre del botón.
 *
 */

import { useState } from "react";
import Alert from "react-bootstrap/Alert";

const AlertPop = ({ texto, titulo, variante, url, reload }) => {
  const [show] = useState(true);

  return (
    <>
      <Alert show={show} variant={variante}>
        <p>{texto}</p>
      </Alert>
    </>
  );
};

export default AlertPop;
