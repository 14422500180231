/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Vista que muestra las visitas activas y pasadas de un familiar.
 * 
 
 */

//#region IMPORTS
import React from "react";

//import componentes
import TablaMisCitas from "../components/TablaMisCitas.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathMisVisitas } from "../components/constants";

//#endregion
const VisitHistory = () => {
  //let fechaCita = new Date(Date.parse("2021-12-06T00:00:00"));

  //#region RENDER
  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathMisVisitas} />
        <div className="container">
          <TablaMisCitas />
        </div>
      </div>
    </>
  );
};
//#endregion

export default VisitHistory;
