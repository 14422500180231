//imports router
import { useParams } from "react-router-dom";

//#region IMPORTS
import React, { useState, useEffect } from "react";

//Imports componentes
import Loader from "../components/Loader.jsx";
import SectionHeader from "../components/SectionHeader.jsx";
import { pathGallery } from "../components/constants";
import Album from "../components/Album.jsx";

//Imports Bootstrap
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BotonAddImg from "./BotonAddImg.jsx";

export const AlbumPage = () => {
  const userInfo = localStorage.getItem("admin");

  const [idAlbum, setIdAlbum] = useState(null);

  const [loadedTheme, setLoadedTheme] = useState(false);

  setTimeout(function () {
    setLoadedTheme(true);
  }, 400);
  const idAlb = useParams();
  useEffect(() => {
    setIdAlbum(idAlb.albId);
  }, []);

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <SectionHeader infoPath={pathGallery} />

        {loadedTheme ? (
          <>
            <Container>
              <Row>
                <Col>
                  <div
                    className="adminAlbum"
                    style={{
                      display: userInfo === "true" ? "inline-block" : "none",
                    }}
                  >
                    <BotonAddImg album={idAlbum} />
                  </div>

                  <Card>
                    <Card.Body>
                      <Album albumId={idAlbum} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};
