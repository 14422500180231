/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Modal generado para ver las visitas y sus detalles.
 * @param onShow Controla el estado del modal, abierto.
 * @param onClose Controla el estado del modal, cerrado
 * @param data Datos que contienen la información enviada desde donde se llama a este componente.
 *
 */

//#region IMPORTS
import React, { useState, useEffect } from "react";
import axios from "axios";

//imports variables
import { urlRequest } from "./constants";

//Imports bootstrap
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

//imports componentes
import AlertPop from "./AlertPop.jsx";

//#endregion

const ModalVerVisita = ({ past, onShow, onClose, data }) => {
  const userInfo = "true";

  const [renderAlert, setRenderAlert] = useState(null);
  const [errorSimul, setErrorSimul] = useState(null);
  const [citaPasada] = useState(past);
  const [urlRedi, setUrlRedi] = useState(null);

  let isAdmin = localStorage.getItem("admin");

  //#region RENDER
  //función cancelar cita
  let compi = data.acomp.replace("[", "");
  compi = compi.replace("]", "");
  compi = compi.replace("'", "");
  compi = compi.replace("'", "");
  let id = data.idreserva;
  let xFecha = new Date(data.fecha);
  let dia = xFecha.getDate();
  let mes = xFecha.getMonth() + 1;
  let year = xFecha.getFullYear();

  const reqCancelarCita = async (e) => {
    let url = urlRequest + "citas/cancelar/" + id + "/";
    let tokenSend = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tokenSend}`,
    };
    return await axios
      .get(url, { headers })
      .then((response) => {
        setRenderAlert(true);
        setErrorSimul(false);
        return response;
      })
      .catch((error) => {
        setRenderAlert(true);
        setErrorSimul(true);
      });
  };

  useEffect(() => {
    if (isAdmin === "true") {
      setUrlRedi("/visitManagement");
    } else {
      setUrlRedi("/visitHistory");
    }
  }, []);

  return (
    <Modal show={onShow} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles de visita</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="zonaComun">
          <Container>
            <Row>
              <Col>
                <span className="tituloVisita"> Nombre del visitante:</span>{" "}
                {data.nombre}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="tituloVisita"> Nombre del residente:</span>{" "}
                {data.nombreResidente}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="tituloVisita">Fecha de la visita:</span> {dia}-
                {mes}-{year}
              </Col>
            </Row>{" "}
            <Row>
              <Col>
                <span className="tituloVisita">Zona:</span> {data.zona}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="tituloVisita">Horario de la visita:</span>{" "}
                {data.horario}
              </Col>
            </Row>{" "}
            <Row>
              <Col>
                <span className="tituloVisita">Teléfono de contacto:</span>{" "}
                {data.telefono}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="tituloVisita">Acompañantes:</span>
                {compi}
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col>
                <br />
                <div
                  className="alerta"
                  style={{
                    display: renderAlert === true ? "inline-block" : "none",
                  }}
                >
                  {errorSimul === false ? (
                    <>
                      <AlertPop
                        titulo="¡Listo!"
                        texto="Visita cancelada correctamente"
                        variante="success"
                        url={urlRedi}
                        reload="true"
                      />
                    </>
                  ) : (
                    <>
                      <AlertPop
                        titulo="Error...ups"
                        texto="No puede reservar más citas de forma simultanea"
                        variante="danger"
                        url={urlRedi}
                        reload="true"
                      />
                    </>
                  )}
                </div>

                <div
                  style={{
                    marginTop: "8px",
                    marginRight: "15px",
                    display: renderAlert === true ? "none" : "inline-block",
                  }}
                  className="holderBoton"
                >
                  {userInfo === "true" ? (
                    <Button
                      style={{
                        display:
                          citaPasada === "true" ? "none" : "inline-block",
                      }}
                      onClick={reqCancelarCita}
                    >
                      Cancelar Visita
                    </Button>
                  ) : (
                    <p></p>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
  //#endregion
};

export default ModalVerVisita;
