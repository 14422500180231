/***
 *
 * @author Hive Mind Solutions S.L.
 * @description Tabla que muestra los menús del centro.
 *
 */
import React, { useEffect } from "react";
//Imports componentes
import BotonDocumento from "./BotonDocumento.jsx";
import BotonBorrarEncuesta from "./BottonBorrarEncuesta";

//import hooks
import { useGetEncuestas } from "../hooks/useGetEncuestas.js";

//Imports bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

const ListaEncuestas = () => {
  //Custom hook que realiza la lógica de request de menus
  const { encuestas, getEncuestaList } = useGetEncuestas();
  //revisar el async si no funciona algo
  useEffect(() => {
    getEncuestaList();
  }, []);

  return (
    <>
      <div className="containerMenu">
        <Container>
          <Row>
            <Col>
              <h1 className="tituloSeccionEnc">Encuestas de satisfacción</h1>
              <p>
                En esta sección podrá rellenar nuestras encuestas de
                satisfacción. Nos interesa su opinión y utilizando estas
                encuestas podremos saber como mejorar nuestros servicios.
              </p>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Título</th>
                  </tr>
                </thead>

                {encuestas &&
                  encuestas.map((data, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>
                          {new Date(data.fecha).toLocaleDateString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>

                        <td>{data.titulo}</td>
                        <td>
                          <BotonDocumento
                            documentTipo={"form"}
                            docUrl={data.link}
                          />
                        </td>
                        <td>
                          <BotonBorrarEncuesta idEncuesta={data.id} />
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListaEncuestas;
